import "../styles/resizer.scss";
import React, { useRef } from "react";
import { useEffect } from "react";
import { AnimationLength } from "../utilities/constants";


export default function Resizer({ className, expanded, children }) {

    let wrapperRef = useRef();
    let sync = useRef({}).current;

    // Can't animate height:auto, so manually set the height during animation
    useEffect(() => {
        let wrap = wrapperRef.current;
        if (!wrap)
            return;

        // Set the destination height (after giving a bit of time for the fixed height to take effect)
        setTimeout(() => wrap.style["height"] = (expanded ? wrap.scrollHeight : 0) + "px", 10);

        // Cleanup inline styles
        let timer = setTimeout(() => {
            wrap.style.removeProperty("height");
            wrap.style.removeProperty("overflow");
            wrap.firstChild.style.removeProperty("display");
        }, AnimationLength + 10);

        return () => timer && clearTimeout(timer);
    }, [expanded]);

    // Save the current height just before animating
    let wrap = wrapperRef.current;
    if (sync.expanded !== expanded && wrap) {
        wrap.style["height"] = (expanded ? "0" : wrap.offsetHeight) + "px"; // Set the beginning height
        wrap.style["overflow"] = "hidden";
        if (!expanded) 
            wrap.firstChild.style["display"] = "block"; // Keep visible during the animation
    }
    sync.expanded = expanded;


    return (
        <div className={"resizer " + (expanded ? "" : " resizer-collapsed ") + (className || "")} ref={wrapperRef}>
            <div className="resizer-content">
                {children}
            </div>
        </div>
    );
}