import '../styles/templates.scss';
import React from 'react';
import PageLayout from '../components/page-layout';
import Card from '../components/card';


export default function Templates() {

    return (
        <PageLayout title="Templates - under construction">
            <Card>
            </Card>
        </PageLayout>
    );
}