import React from 'react';
import { useSelector } from 'react-redux';


const MyAlertsDesktop = React.lazy(() => import('./MyAlerts-Desktop.jsx'));
const MyAlertsMobile = React.lazy(() => import('./MyAlerts-Mobile.jsx'));
const LazyMyAlertsDesktop = () => <React.Suspense fallback={<div className="loading-page"><i className="fa fa-spinner fa-spin"></i></div>}><MyAlertsDesktop /></React.Suspense>;
const LazyMyAlertsMobile = () => <React.Suspense fallback={<div className="loading-page"><i className="fa fa-spinner fa-spin"></i></div>}><MyAlertsMobile /></React.Suspense>;


export default function MyAlerts() {
    let isMobile = useSelector(store => store.page.isMobile);

    if (isMobile)
        return <LazyMyAlertsMobile />
    else
        return <LazyMyAlertsDesktop />
}
