import React, { useState } from "react";
import "../styles/BottomNavBar.scss";

const BottomNavBar = () => {
  const [activeTab, setActiveTab] = useState("dashboard");

  return (
    <div className="bottom-nav-bar">
      <div
        className={`nav-item ${activeTab === "dashboard" ? "active" : ""}`}
        onClick={() => setActiveTab("dashboard")}
      >
        <i className="fa fa-home"></i>
        <span>My Dashboard</span>
      </div>
      <div
        className={`nav-item ${activeTab === "messages" ? "active" : ""}`}
        // onClick={() => setActiveTab("messages")}
      >
        <i className="fa fa-paper-plane"></i>
        <span>Messages</span>
      </div>
      <div
        className={`nav-item ${activeTab === "people" ? "active" : ""}`}
        // onClick={() => setActiveTab("people")}
      >
        <i className="fa fa-user"></i>
        <span>People</span>
      </div>
      <div
        className={`nav-item ${activeTab === "groups" ? "active" : ""}`}
        // onClick={() => setActiveTab("groups")}
      >
        <i className="fa fa-users"></i>
        <span>Groups</span>
      </div>
    </div>
  );
};

export default BottomNavBar;
