import "../../styles/global-ui/gui-calendar.scss";
import 'react-calendar/dist/Calendar.css';
import React from "react";
import { Calendar } from 'react-calendar';


export default function GuiCalendar({ name, value, onChange }) {
    return (
        <Calendar className="gui-calendar" name={name} value={value} onChange={value => onChange(name, value)} calendarType="US" />
    )
}