import '../styles/voice-recorder.scss';
import React, { useRef, useState } from "react";
import audioRecorder from '../utilities/audio-recording';
import Error from './global-ui/error';
import AudioPlayer from './audio-player';
import { Icon } from './icon';
import { padLeft } from '../utilities/utilities';


export function VoiceRecorder({ name, value, error, onChange, autoPlay }) {
    let [state, setState] = useState({});
    let timerDisplayRef = useRef();
    let stopRef = useRef();

    function recordClick() {
        audioRecorder.start()
            .then(() => {
                let timer = startTimer(timerDisplayRef, 120, stopRef);
                setState({ ...state, recording: true, timer: timer });
            })
            .catch(error => {
                window.alert(error.message || error.name);
            });
    }

    function stopClick() {
        if (state.timer)
            clearInterval(state.timer);

        audioRecorder.stop()
            .then(audioBlob => {
                setState({ ...state, recording: false });
                onChange(name, audioBlob);
            })
            .catch(error => {
                setState({ ...state, recording: false });
                window.alert(error.message || error.name);
            });
    }
    stopRef.current = stopClick;

    return (
        <>
            <div className={"voice-recorder box-card" + (error ? " has-error" : "")}>
                <div className="box-body">
                    {!state.recording ?
                        <span className="record-btn" onClick={recordClick}>
                            <Icon icon="microphone" />
                        </span>
                        :
                        <span className="record-btn recording" onClick={stopClick}>
                            <Icon icon="square-full" />
                            <span className="recording-time" ref={timerDisplayRef}></span>
                        </span>
                    }

                    {value &&
                        <div className="player">
                            <AudioPlayer src={value} autoPlay={autoPlay} controls />
                        </div>
                    }
                </div>
                <Error error={error} />
            </div>
        </>
    );
}

function startTimer(timerDisplayRef, maxTime, stopRef) {
    let startTime = new Date();
    let maxSeconds = 120;

    let timer = setInterval(s => {
        let seconds = Math.floor((new Date() - startTime) / 1000);
        let remaining = maxSeconds - seconds;

        let text = "";
        if (remaining > 60)
            text = Math.floor(remaining / 60) + ":" + padLeft(remaining % 60, 2, '0');
        else
            text = remaining + "s";

        if (timerDisplayRef.current)
            timerDisplayRef.current.innerText = text;

        if (remaining <= 0) {
            clearInterval(timer);
            if (stopRef.current)
                stopRef.current();
        }
    }, 100);
    return timer;
}


// Convert to MP3 in browser
// https://devtails.medium.com/how-to-convert-audio-from-wav-to-mp3-in-javascript-using-ffmpeg-wasm-5dcd07a11821
// example: https://ffmpegwasm.netlify.app/#installation
// specifiy your own hosting (https://github.com/ffmpegwasm/ffmpeg.wasm)
//      const ffmpeg = createFFmpeg({ corePath: new URL('static/js/ffmpeg-core.js', document.location).href });

//import { createFFmpeg } from '@ffmpeg/ffmpeg';
//const ffmpeg = createFFmpeg({ log: true });
//const transcode = async (audioBlob) => {
//    const name = "recording.webm";
//    await ffmpeg.load(); // This loads up the ffmpeg.wasm files from a CDN
//    const arrayBuffer = await audioBlob.arrayBuffer()
//    const uint8Array = new Uint8Array(arrayBuffer);
//    ffmpeg.FS("writeFile", name, uint8Array);
//    await ffmpeg.run('-i', name, 'recording.mp3');
//    const data = ffmpeg.FS('readFile', 'recording.mp3');

//    //const track = document.getElementById("track");
//    //track.src = URL.createObjectURL(new Blob([data.buffer], { type: 'audio/mpeg' }));
//}