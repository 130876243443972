import '../styles/merge-groups.scss';
import React from "react";
import { Modal } from './modal';
import { useUpdateState } from '../utilities/utilities';
import { GuiRadio } from './global-ui/gui-radio';
import { GuiSelect } from './global-ui/gui-select';
import { GroupProperties } from './group-properties';
import { GuiButton } from './global-ui/gui-button';
import actions from '../actions/actions';
import { useOnFirstLoad } from '../utilities/hooks';
import { useSelector } from 'react-redux';
import { dispatch } from '../redux/redux-config';


export function MergeGroupsModal({ mergeSourceIds, onClose }) {
    let [state, updateState] = useUpdateState({ settings: {} });
    let groupOptions = useSelector(store => actions.groups.loadGroupOptions()) || [];

    useOnFirstLoad(() => {
        // Load Settings
        actions.groups.getGroupDetails(0)
            .then(result => {
                if (!result.success)
                    return;

                result.settings.groupTypes = result.settings.groupTypes.map(x => ({ value: x.id, label: x.name }));
                if (!result.group.id)
                    result.group.groupType = 5; // Default to "Custom"

                updateState({
                    newGroup: result.group,
                    settings: result.settings
                });
            });
    });


    function updateGroup(name, value) {
        value = (value?.value || value?.value === 0) ? value.value : value; // Change dropdown option into value
        let updated = { ...state.newGroup, [name]: value };
        updateState("newGroup", updated);
    }

    async function onSave() {
        updateState("saving", true);

        let destinationGroupId = state.createNew === "true" ? null : state.groupId;
        let newGroup = state.createNew === "true" ? state.newGroup : null;
        let result = await actions.groups.merge(mergeSourceIds, destinationGroupId, newGroup);
        if (result.success) {
            onClose(true);
            dispatch("GROUP_LIST_CHANGED");
        }
        else
            updateState("saving", false);
    }


    return (
        <Modal className="plain-modal merge-group-modal">
            <span>Are you sure you want to merge groups?</span>

            <div className="merge-type">
                <GuiRadio label="Merge into new group" name="createNew" id="true" value={state.createNew} onChange={updateState} />
                <GuiRadio label="Merge into existing group" name="createNew" id="false" value={state.createNew} onChange={updateState} />
            </div>

            {state.createNew === "true" && 
                <GroupProperties group={state.newGroup || {}} settings={state.settings} onChange={updateGroup} />
            }

            {state.createNew === "false" &&
                <div>
                    <GuiSelect name="groupId" options={groupOptions} value={state.groupId} onChange={(name, option) => updateState(name, option.value)} />
                </div>
            }

            <div className="buttons">
                <GuiButton className="btn-outline" onClick={e => onClose(false)}>Cancel</GuiButton>
                <GuiButton onClick={() => onSave(state)} loading={state.saving}>Merge Groups</GuiButton>
            </div>
        </Modal>
    );
}
