import React, { useContext } from 'react'; // eslint-disable-line
import ValidationContext from './validation';


export default function Error({ name, error }) {
    let validation = useContext(ValidationContext);
    if (name)
        error = error || (validation && validation.show && validation.getDisplayError(name));

    if (!error)
        return null;
    return <span className="error-msg">{error}</span>
}