import { apiRequestWithChecks } from "./api";


export const report = {
    getRecentActivity: function (token, start, end, type, sort, pageNum, pageSize, skipTotal) {
        return apiRequestWithChecks("PUT", "/api/report/recent-activity", {            
            "Authorization" : "Bearer " + token
        },
            {
                Start: start || null,
                End: end || null,
                ActivityType: type || null,
                Sort: sort,
                PageNum: pageNum,
                PageSize: pageSize,
                SkipTotal: skipTotal
            });
    },

    getOptOutReport: function (token, search, sort, pageNum, pageSize, skipTotal) {
        return apiRequestWithChecks(
          "POST",
          "/api/optoutreport",
          {            
            "Authorization" : "Bearer " + token
          },
          {
            searchText: search ||  null,
            sort: sort,
            pageNum: pageNum,
            pageSize: pageSize,
            skipTotal: skipTotal
          }
        );
    }
}