import '../styles/filter-tag-bar.scss';
import React from "react";
import { Tag } from './tag';


export function FilterTagBar({ filters, onRemove }) {

    return (
        <div className="filter-tags">
            {filters.map((f, i) =>
                <Tag className="filter" label={f.label} onRemove={e => onRemove(f)} key={i} />
            )}
        </div>
    );
}

