import api from "../api/api";
import { appState, dispatch } from "../redux/redux-config";



const files = {
    loadImageLibrary: async function () {
        let token = appState().user.token;
        let result = await api.file.getLibraryItems(token);
        if (result.success) {
            dispatch("UPDATE_FILES", { library: result.items || [] });
        }
    },

    saveLibraryItem: async function (item) {
        let token = appState().user.token;
        let result = await api.file.saveLibraryItem(token, item);
        if (result.success) {
            return result.item;
        }
    },

    uploadBase64Item: async function (filename, data, tag, overwriteId) {
        let token = appState().user.token;
        let result = await api.file.uploadBase64Item(token, filename, data, tag, overwriteId);
        if (result.success) {
            return result.data;
        }
    },

    deleteLibraryItem: async function (img) {
        let token = appState().user.token;
        await api.file.deleteLibraryItem(token, img.id);
    },

    duplicateLibraryItem: async function (itemId) {
        let token = appState().user.token;
        var result = await api.file.duplicateLibraryItem(token, itemId);
        if (result.success)
            return result.item;
    }
}
export default files;






export function filesReducer(state, action) {
    if (state === undefined)
        return initialState;


    switch (action.type) {
        case 'UPDATE_FILES':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}

const initialState = {
};