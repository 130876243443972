import '../styles/successful.scss';
import React from 'react';
import actions from '../actions/actions';
import { AlertStatus } from '../utilities/constants';
import { GuiButton } from '../components/global-ui/gui-button';
import navigate from '../utilities/navigation';


export default function Successful() {

    function gotoOverview() {
        navigate("/");
        actions.track.navigateToTrack(AlertStatus.All)
    }


    return (
        <div className="successful-page">
            <div className="container-card">
                <div className="body-container">
                    <img src="/lib/images/sent-artwork.svg" />
                </div>
                <div className="btn-container">
                    <GuiButton onClick={gotoOverview}>Ok</GuiButton>
                </div>
            </div>
        </div>
    );
}