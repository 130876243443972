import "../../styles/global-ui/gui-textarea.scss";
import React, { useContext, useState } from "react";
import ValidationContext from './validation';
import Error from "./error";


export function GuiTextArea({ dataQa, name, label, value, onChange, disabled, type, className, style, onKeyUp, error, maxLength }) {
    let validation = useContext(ValidationContext);
    let [inFocus, setInFocus] = useState(false);
    error = error || (validation && validation.show && validation.getDisplayError(name));

    return (
        <div className={"gui-textarea " + (className || "") + (value ? " has-value" : "") + (inFocus ? " in-focus" : "") + (error ? " has-error" : "")}>
            <textarea
                type={type || "text"}
                name={name}
                value={value || ""}
                onChange={e => onChange ? onChange(name, e.target.value) : null}
                onFocus={e => setInFocus(true)}
                onBlur={e => setInFocus(false)}
                onKeyUp={onKeyUp}
                disabled={disabled || false}
                maxLength={maxLength}
                style={style}
                data-qa={dataQa}
            />
            {label && <label className="control-label">{label}</label>}
            <Error error={error} />
        </div>
    );
}