import '../styles/popover.scss';
import React, { useEffect } from "react";


export default function Popover({ show, onHide, className, children }) {
    if (!show)
        return null;

    return <PopoverPanel className={className} onHide={onHide}>{children}</PopoverPanel>;
}


function PopoverPanel({ onHide, className, children }) {

    useEffect(() => {

        function documentClick(e) {
            if (!e.target.closest(".popover, .ss__menu") && onHide) {
                e.preventDefault();
                e.stopPropagation();
                onHide();
            }
        }

        document.addEventListener('click', documentClick, { capture: true });
        document.addEventListener('touchstart', documentClick, { capture: true, passive: false });
        return () => {
            document.removeEventListener('click', documentClick, { capture: true });
            document.removeEventListener('touchstart', documentClick, { capture: true, passive: false });
        }
    }, []);


    return (
        <div className={"popover-anchor " + (className || "")}>
            <div className="popover">
                {children}
            </div>
        </div>
    );
}
