import '../styles/column-picker.scss';
import React, { useState } from "react";
import Popover from './popover';
import { GuiButton } from './global-ui/gui-button';
import { GuiCheckbox } from './global-ui/gui-checkbox';
import { Icon } from './icon';


export function ColumnPicker({ items, onItemChange }) {
    let [show, setShow] = useState(false);

    function onChange(item, e) {
        if (e.target.tagName === "INPUT") // a duplicate click event fires on the input
            return;
        onItemChange(item, !item.show);
    }

    return (
        <>
            <GuiButton className="btn-text columns-cog" onClick={() => setShow(!show)} dataQa="settings-button"><Icon icon="cog" /></GuiButton>
            <Popover show={show} className="no-arrow anchor-right popover-menu column-picker" onHide={() => setShow(false)}>
                {items.map((item, i) =>
                    <div className="menu-item" onClick={e => onChange(item, e)} key={i}><GuiCheckbox name={item.name} label={item.name} value={item.show} /></div>
                )}
            </Popover>
        </>
    );
}

