import { useState } from 'react';
import actions from '../../actions/actions';

export function useAddUserToGroup(checkboxState) {
  const [showAddToGroupModal, setShowAddToGroupModal] = useState(false);
  const [addToGroupId, setAddToGroupId] = useState(null);
  const [individual, setIndividual] = useState(null);
  const [currentGroup, setCurrentGroup] = useState(null); 
  const [saving, setSaving] = useState(false);

  async function addUsersToGroup() {
    console.log('we are inside the addUsersToGroup function');
    console.log('showAddToGroupModal:', showAddToGroupModal);
    console.log('addToGroupId:', addToGroupId);
    console.log('individual:', individual);
    console.log('saving:', saving);
    let userIds = (individual ? [individual.id] : Object.keys(checkboxState).filter(k => checkboxState[k]));
    setSaving(true);
    await actions.people.addPeopleToGroup(addToGroupId, userIds);
    setSaving(false);
    setShowAddToGroupModal(true);
    setAddToGroupId(null);
    setIndividual(null);
  }

  function openAddUserToGroupModal(individualUser, currentGroup) {
    console.log('we are inside the openAddUserToGroupModal function');
    console.log('showAddToGroupModal:', showAddToGroupModal);
    console.log('addToGroupId:', addToGroupId);
    console.log('individual:', individual);
    console.log('saving:', saving);
    setShowAddToGroupModal(true);
    setIndividual(individualUser);
    setCurrentGroup(currentGroup);
  }

  return {
    showAddToGroupModal,
    setShowAddToGroupModal,
    addToGroupId,
    individual,
    saving,
    addUsersToGroup,
    openAddUserToGroupModal,
    setAddToGroupId,
  };
}