import { apiRequestWithChecks } from "./api";


export const contacts = {
    list: function (token, search, pageNum, pageSize, skipTotal) {
        return apiRequestWithChecks("GET", "/api/contacts/list?search=" + encodeURIComponent(search) + "&pageNum=" + pageNum + "&pageSize=" + pageSize + (skipTotal ? "&skipTotal=true" : ""), {            
            "Authorization" : "Bearer " + token
        });
    },

    InvalidContacts: function (token) {
        return apiRequestWithChecks("GET", "/api/contacts/invalid", {            
            "Authorization" : "Bearer " + token
        });
    }, 

    DeleteContacts: function (token, selectedContactIds) {
      return apiRequestWithChecks("DELETE", "/api/contacts/delete", {        
        "Authorization" : "Bearer " + token
      }, selectedContactIds);
  }
}