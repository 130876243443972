import "../../styles/global-ui/gui-tooltip.scss";
import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";


export function GuiTooltip({ show, tooltip, children, placement, className, trigger, onlyIfTruncatedRef }) {
    // OnlyIfTruncated
    const [needed, setNeeded] = useState(true);
    useEffect(() => {
        if (!onlyIfTruncatedRef || !onlyIfTruncatedRef.current)
            return;

        const truncated = hasEllipsis(onlyIfTruncatedRef.current);
        if (needed !== truncated)
            setNeeded(truncated);
    });
    if (!needed || (show !== undefined && !show))
        return children;

    return (
        <OverlayTrigger placement={placement || "top"} trigger={trigger} rootClose={true} overlay={<Tooltip className={"gui-tooltip " + (className || "")} transition={false} animation={false}>{tooltip}</Tooltip>}>
            {children}
        </OverlayTrigger>
    );
}

export function hasEllipsis(element) {
    return element.offsetWidth < element.scrollWidth;
}