import api from "../api/api";
import { appState, dispatch } from "../redux/redux-config";
import { Path, PeopleTab } from "../utilities/constants";
import navigate from "../utilities/navigation";
import { storage } from "../utilities/storage";
import { admin as apiAdmin } from "../api/admin-api";

const admin = {
  getCommunicationReport: async function (startDate, endDate, commType, sort, pageNum, pageSize, skipTotal) {
    let token = appState().user.token;

    let result = await apiAdmin.getCommunicationReport(token, startDate, endDate, commType, sort, pageNum, pageSize, skipTotal);
    if (result.success) {
      return result;
    }
  }
}
export default admin;