import "../../styles/global-ui/gui-button.scss";
import React from "react";
import { Spinner } from "../loading-spinner";


export function GuiButton({ className, children, disabled, loading, onClick, data, dataQa, title }) {
    return (
        <button className={"gui-btn " + (className || "") + (disabled || loading ? " disabled" : "")} 
          onClick={e => !disabled && !loading && onClick(e, data)} 
          type="button" 
          data-qa={dataQa}
          title={title}>
            {!loading ? children : <Spinner />}
        </button>
    );
}