import React from "react";
import "../../styles/invalid-contacts.scss";
import Card from "../../components/card";
import { SearchBox } from "../../components/search-box";
import {
  downloadFile,
  pluralize,
  useUpdateState,
} from "../../utilities/utilities";
import { GuiButton } from "../../components/global-ui/gui-button";
import { Icon, Initials } from "../../components/icon";
import contacts from "../../actions/contact-actions";
import Grid, { ColumnHeader, GridCell } from "../../components/grid";
import {
  CheckBoxHeaderButton,
  GuiCheckbox,
} from "../../components/global-ui/gui-checkbox";
import { Modal } from "../../components/modal";
import actions from "../../actions/actions";

export default function InvalidContacts() {
  let [state, updateState] = useUpdateState(() => {
    return {
      search: "",
      filters: [],
      sort: {},
      gridReset: 0,
      currentPage: [],
    };
  });

  function setSearch(search) {
    updateState({ search: search, gridReset: state.gridReset + 1 });
  }
  let [checkboxState, updateCheckboxState, setCheckboxState] = useUpdateState(
    {}
  );
  let selectedRows = Object.keys(checkboxState).filter((k) => checkboxState[k]);
  const areAnyRowsSelected = selectedRows.length > 0;
  let { sort } = state;

  function onSort(column) {
    let currentDirection = sort[column];
    let nextDirection =
      currentDirection === true
        ? false
        : currentDirection === false
        ? undefined
        : true;
    updateState({
      sort: { ...sort, [column]: nextDirection },
      gridReset: state.gridReset + 1,
    });
  }

  function getSort() {
    return Object.keys(sort)
      .map((col) => ({ column: col, asc: sort[col] }))
      .filter((s) => s.asc !== undefined)
      .map(
        (s) =>
          s.column +
          (s.asc ? "" : " desc") +
          (s.column === "firstName"
            ? ", lastName" + (s.asc ? "" : " desc")
            : "")
      )
      .join(", ");
  }

  function loadRows(page, pageSize, skipTotal) {
    let sort = getSort();
    return new Promise((resolve, reject) => {
      contacts
        .InvalidContacts()
        .then((result) => {
          let filteredContacts = result.contacts;
          if (state.search) {
            const searchTerm = state.search.toLowerCase();
            filteredContacts = filteredContacts.filter(
              (contact) =>
                (contact.firstName &&
                  contact.firstName.toLowerCase().includes(searchTerm)) ||
                (contact.lastName &&
                  contact.lastName.toLowerCase().includes(searchTerm)) ||
                (contact.phone &&
                  contact.phone.toLowerCase().includes(searchTerm)) ||
                (contact.contactTypeName &&
                  contact.contactTypeName.toLowerCase().includes(searchTerm)) ||
                (contact.description &&
                  contact.description.toLowerCase().includes(searchTerm))
            );
          }
          const start = (page - 1) * pageSize;
          const end = start + pageSize;
          const list = filteredContacts.slice(start, end);
          const totalCount = skipTotal ? undefined : filteredContacts.length;
          resolve({ list, totalCount });
        })
        .catch(reject);
    });
  }

  function toggleAllCheckboxes() {
    let any = Object.keys(checkboxState).find((k) => checkboxState[k]);
    if (any) {
      setCheckboxState({});
    } else {
      let newState = state.currentPage.reduce((newState, row) => {
        newState[row.id] = true;
        return newState;
      }, {});
      setCheckboxState(newState);
    }
  }

  function onPageChanged(list) {
    setCheckboxState({});
    updateState({ currentPage: list });
  }

  async function removeSelectedContacts() {
    let removeSelectedRows = Object.keys(checkboxState).filter(
      (k) => checkboxState[k]
    );
    const selectedContactIds = removeSelectedRows.map((row) => parseInt(row));
    updateState({ saving: true });
    const response = await actions.contacts.DeleteContacts(selectedContactIds);
    updateState({
      saving: false,
      showDeleteContactsModal: false,
      individual: null,
      gridReset: state.gridReset + 1,
    });
    updateState({ showDeleteContactsModal: false });
  }

  return (
    <Card>
      <div className="page-layout-content-header">
        <SearchBox
          onSearchChange={setSearch}
          searching={state.loading}
          minLength={3}
          dataQa="search"
        />
        <GuiButton
          className="btn btn-outline ml-auto"
          disabled={!areAnyRowsSelected}
          onClick={(e) => updateState({ showDeleteContactsModal: true })}
        >
          <Icon icon="trash" />
          Remove Contacts
        </GuiButton>
      </div>
      <Grid
        header={
          <div className="header-row row">
            <ColumnHeader className="check">
              <CheckBoxHeaderButton
                checked={Object.keys(checkboxState).find(
                  (k) => checkboxState[k]
                )}
                onClick={toggleAllCheckboxes}
              />
            </ColumnHeader>
            <ColumnHeader
              className="user"
              sort={sort}
              onSort={() => onSort("User")}
            >
              User
            </ColumnHeader>
            <ColumnHeader
              className="contact-type"
              sort={sort}
              onSort={() => onSort("contact-type")}
            >
              Contact Type
            </ColumnHeader>
            <ColumnHeader
              className="contact-method"
              sort={sort}
              onSort={() => onSort("contact-method")}
            >
              Contact Method
            </ColumnHeader>
            <ColumnHeader
              className="invalid-type"
              sort={sort}
              onSort={() => onSort("invalid-type")}
            >
              Invalid Type
            </ColumnHeader>
          </div>
        }
        itemTemplate={(p) => (
          <div className="row" key={p.id}>
            <GridCell className="check">
              {console.log("p.contactId", p.contactId)}
              <GuiCheckbox
                name={"" + p.contactId}
                value={checkboxState[p.contactId]}
                onChange={updateCheckboxState}
              />
            </GridCell>
            <GridCell className="firstName">
              <span className="account">
                {p.firstName?.substr(0, 1) + p.lastName?.substr(0, 1) ||
                  "\u00A0"}
              </span>
              {`${p.firstName} ${p.lastName}`}
            </GridCell>
            <GridCell className="numberDialed">{p.ctName}</GridCell>
            <GridCell className="alertType">{p.phone}</GridCell>
            <GridCell className="alertSubject">{p.description}</GridCell>
          </div>
        )}
        onItemsNeeded={loadRows}
        reset={state.gridReset}
        pageSize={20}
        onPageChanged={onPageChanged}
        mobileItemHeight={65}
      />

      {/* Delete */}
      {state.showDeleteContactsModal && (
        <Modal className="plain-modal">
          <p>Are you sure you want to permanently remove the contact ?</p>
          <div className="buttons">
            <GuiButton
              className="ml-auto btn-outline"
              onClick={(e) =>
                updateState({
                  showDeleteContactsModal: false,
                  individual: null,
                })
              }
            >
              Cancel
            </GuiButton>
            <GuiButton
              className="warning"
              onClick={removeSelectedContacts}
              loading={state.saving}
            >
              Delete {state.individual ? "" : Object.keys(checkboxState).length}{" "}
              User
              {state.individual || Object.keys(checkboxState).length === 1
                ? ""
                : "s"}
            </GuiButton>
          </div>
        </Modal>
      )}
    </Card>
  );
}
