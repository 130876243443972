import React from "react";
import { getInitials } from "../utilities/utilities";


export function Icon({ icon, className, weight, button, onClick }) {

    // fyi, <Icon /> doesn't work with Tooltips, so use <i> instead

    return <i className={"far fa-" + icon + (className ? " " + className : "") + (button ? " icon-button" : "")} style={{ fontWeight: weight || "900" }} onClick={onClick} />
}

export function SeparatorDot() {
    return <span className="separator-dot" />;
}

export function Initials({ person, firstName, lastName }) {
    firstName = firstName || person.firstName;
    lastName = lastName || person.lastName;

    return <span className="initials">{getInitials(firstName, lastName)}</span>
}