import React, { useEffect, useState } from "react";
import "../styles/profile-card.scss";
import { GuiButton } from "./global-ui/gui-button";
import { appState } from "../redux/redux-config";
import api from "../api/api";
import { useOnFirstLoad } from "../utilities/hooks";
import { formatPhone, useUpdateState } from "../utilities/utilities";
import CircularProgress from "../actions/CircularProgress";
import { GuiTooltip } from "./global-ui/gui-tooltip";
import { isMobile } from "../utilities/constants";

export default function ProfileCard({
  onEditClick,
  isEditing,
  isComponentDisabled,
  onProfileEdit,
  onAddContact,
  onEditContact,
  onEditPreference,
}) {
  const [loading, setLoading] = useState(true);
  const [loaderError, setError] = useState(false);

  const [state, updateState] = useUpdateState({
    primaryEmail: "",
    notificationEmail: "",
    cellPhone: "",
    homePhone: "",
    workPhone: "",
    voice: "",
    address: "",
    name: "",
    role: "",
    orgName: "",
    contactEmail: "",
    contactsArray: [],
    userId: "",
    smsEnableNotify: false,
  });

  function onBtnClick() {
    console.log("Button clicked");
  }
  function onEditPreferenceClick() {
    const payload = {
      selectedVoiceId: voice,
      enableEmail: state.smsEnableNotify,
      email: state.notificationEmail,
    };
    onEditPreference(payload);
  }

  useEffect(() => {
    let timeoutId;
    if (loading) {
      timeoutId = setTimeout(() => {
        setLoading(false);
        setError(true);
      }, 15000);
    }
    // Cleanup the timeout on component unmount or when loading changes
    return () => clearTimeout(timeoutId);
  }, [loading]);

  useOnFirstLoad(() => {
    const token = appState().user.token;
    setError(false);
    // Fetch the user profile
    try {
      api.user.getUserProfile(token).then((result) => {
        if (result.success) {
          setLoading(false); // Mark loading as done

          updateState({
            role: result.role,
            contactsArray: result.contacts,
            userId: result.userId,
          });
          updateState({ orgName: result.customerName });
          // Extract name fo the user
          const name = result.firstName + " " + result.lastName;
          updateState({ name: name });
          const contacts = result.contacts || [];

          updateState({
            primaryEmail: result.emailAddress,
          });

          // Extract preference email and if we have empty preference email then show primary email
          const preferences = result.preferences || {};
          const notificationEmailContact =
            preferences.textNotificationEmail.smsNotifyEmail || "";

          updateState({
            notificationEmail:
              notificationEmailContact === ""
                ? result.emailAddress
                : notificationEmailContact,
            smsEnableNotify: preferences.textNotificationEmail.smsEnableNotify,
          });

          // Extract cell phone , work phone and home phone values.

          const emailContact = contacts.find(
            (contact) => contact.contactTypeName === "Email"
          );
          const contactEmail =
            emailContact === undefined
              ? "-"
              : emailContact.phone === undefined || ""
              ? "-"
              : emailContact.phone;

          const cellPhone = getPhoneNumber(contacts, "Cell Phone");
          const workPhone = getPhoneNumber(contacts, "Work Phone");
          const homePhone = getPhoneNumber(contacts, "Home Phone");
          updateState({
            cellPhone: cellPhone,
            homePhone: homePhone,
            workPhone: workPhone,
            contactEmail: contactEmail,
          });

          // Extract voice values

          const voicePreferences = preferences.voice || {};
          const voice = voicePreferences
            ? voicePreferences.langDesc + " - " + voicePreferences.voice
            : "-";

          updateState({ voice: voice });
        } else {
          setError(true);
          setLoading(false);
        }

        // Extract values for address : Filters out undefined, null, and empty strings/spaces
        const address =
          [result.address, result.city, result.state, result.zip]
            .filter((value) => value && value.trim())
            .join(" ")
            .trim() || "-";

        updateState({ address: address });
      });
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  });
  const getPhoneNumber = (contacts, contactTypeName) => {
    const contact = contacts.find(
      (contact) => contact.contactTypeName === contactTypeName
    );
    const formattedPhone = contact
      ? formatPhone(contact.phone) === ""
        ? "-"
        : formatPhone(contact.phone)
      : "-";
    return formattedPhone;
  };

  const {
    primaryEmail,
    notificationEmail,
    cellPhone,
    homePhone,
    workPhone,
    voice,
    address,
    name,
    role,
    orgName,
    contactsArray,
    contactEmail,
    userId,
  } = state;
  const editContactDetails = (contactTypeName) => {
    const contact = contactsArray.find(
      (contact) => contact.contactTypeName === contactTypeName
    );

    contactTypeName === "Address"
      ? onEditContact({
          contactTypeName: contactTypeName,
          userId: userId,
        })
      : onEditContact(contact);
  };
  return (
    <div
      className={`${loaderError && "loader-error"} profile-card ${
        isComponentDisabled && "disabled"
      }`}
    >
      {loading && <CircularProgress />}
      {!loading && loaderError && (
        <span>Failed to load data, please try again later.</span>
      )}
      {!loading && !loaderError && (
        <>
          <div className={`profile-header ${isEditing ? "disabled" : ""}`}>
            <div className="background-curve"></div>
            <div className="profile-image-container">
              {/* add this when we have profile picture functionality */}
              {/* <img
            src={image} // Replace with actual image path
            alt="Profile"
            className="profile-image"
          /> */}
              <div className="initials">
                {name
                  .split(" ")
                  .map((n) => n[0])
                  .join("")}
              </div>
            </div>
            <div className="profile-info">
              <div className="profile-name">
                <h4>{name}</h4>
                <div
                  className="edit-icon cursor-pointer"
                  onClick={onProfileEdit}
                >
                  {isMobile() ? (
                    <i className="fa fa-pencil"></i>
                  ) : (
                    <GuiTooltip tooltip="Edit Profile" placement="right">
                      <i className="fa fa-pencil"></i>
                    </GuiTooltip>
                  )}
                </div>
              </div>
              {/* need to get organization name */}
              <div className="org-name">
                <i className="fa fa-building"></i>
                <p className="organization">{orgName}</p>
              </div>
            </div>
          </div>

          <div className="profile-card-body">
            <div className={`permissions ${isEditing ? "disabled" : ""}`}>
              <p className="section-title">Permissions</p>
              <div className="section-body">
                <div>
                  <i className="fa fa-key"></i>
                  <span className="section-body-title">My Role</span>
                </div>
                <GuiButton className="role-badge" onClick={onBtnClick}>
                  {role}
                </GuiButton>
              </div>
            </div>

            <div className={`primary-contact ${isEditing ? "disabled" : ""}`}>
              <p className="section-title">Primary Contact</p>
              <div className="section-body">
                <div>
                  <i className="fa fa-envelope"></i>
                  <span className="section-body-title">Email</span>
                </div>
                <span className="section-body-title">{primaryEmail}</span>
              </div>
            </div>
            <div className="profile-horizontal-divider"></div>
            <div className="contact-methods">
              <div className="section-title">
                <div className="section-title-header">
                  <div>Contact Methods</div>
                  <div className="add-edit-btns">
                    <GuiButton
                      className="add-edit-contact"
                      onClick={onAddContact}
                      disabled={isEditing}
                    >
                      <i className="fa fa-add"></i> Contact
                    </GuiButton>
                    <GuiButton
                      className={`add-edit-contact ${
                        isEditing ? "cancel-edit-active" : ""
                      }`}
                      onClick={onEditClick}
                    >
                      {isMobile() ? (
                        <>
                          {isEditing ? (
                            <div>
                              {" "}
                              <i className="fa fa-close"></i>Cancel Edit
                            </div>
                          ) : (
                            <div>
                              <i className="fa fa-pencil"></i> Edit
                            </div>
                          )}
                        </>
                      ) : (
                        <GuiTooltip
                          tooltip="Edit Contact Methods"
                          placement="right"
                        >
                          {isEditing ? (
                            <div>
                              {" "}
                              <i className="fa fa-close"></i>Cancel Edit
                            </div>
                          ) : (
                            <div>
                              <i className="fa fa-pencil"></i> Edit
                            </div>
                          )}
                        </GuiTooltip>
                      )}
                    </GuiButton>
                  </div>
                </div>
              </div>

              <div className="section-body">
                <div>
                  <i className="fa fa-envelope"></i>
                  <span className="section-body-title">Email</span>
                </div>
                <span className="section-body-title">
                  <div className="word-break">{contactEmail} </div>
                  {isEditing && contactEmail !== "-" && (
                    <i
                      className="fa fa-pencil ml-2 cursor-pointer"
                      onClick={() => editContactDetails("Email")}
                    ></i>
                  )}
                </span>
              </div>

              <div className="section-body">
                <div>
                  <i className="fa fa-mobile-notch"></i>
                  <span className="section-body-title">Cell Phone</span>
                </div>
                <span className="section-body-title">
                  {cellPhone}
                  {isEditing && cellPhone !== "-" && (
                    <i
                      className="fa fa-pencil ml-2 cursor-pointer"
                      onClick={() => editContactDetails("Cell Phone")}
                    ></i>
                  )}
                </span>
              </div>

              <div className="section-body">
                <div>
                  <i className="fa fa-phone"></i>
                  <span className="section-body-title">Home Phone</span>
                </div>
                <span className="section-body-title">
                  {homePhone}
                  {isEditing && homePhone !== "-" && (
                    <i
                      className="fa fa-pencil ml-2 cursor-pointer"
                      onClick={() => editContactDetails("Home Phone")}
                    ></i>
                  )}
                </span>
              </div>

              <div className="section-body">
                <div>
                  <i className="fa fa-phone-office"></i>
                  <span className="section-body-title">Work Phone</span>
                </div>
                <span className="section-body-title">
                  {workPhone}
                  {isEditing && workPhone !== "-" && (
                    <i
                      className="fa fa-pencil ml-2 cursor-pointer"
                      onClick={() => editContactDetails("Work Phone")}
                    ></i>
                  )}
                </span>
              </div>

              <div className="section-body">
                <div>
                  <i className="fa fa-map-marker-alt"></i>
                  <span className="section-body-title">Address</span>
                </div>
                <span className="section-body-title">
                  <div className="word-break">{address} </div>
                  {isEditing && address !== "-" && (
                    <div>
                      <i
                        className="fa fa-pencil ml-2 cursor-pointer"
                        onClick={() => editContactDetails("Address")}
                      ></i>
                    </div>
                  )}
                </span>
              </div>
            </div>
            <div className="profile-horizontal-divider"></div>
            <div className={`preferences ${isEditing ? "disabled" : ""}`}>
              <div className="section-title">
                <div>Preferences</div>
                <GuiButton
                  className="add-edit-contact"
                  onClick={onEditPreferenceClick}
                >
                  {isMobile() ? (
                    <div>
                      <i className="fa fa-pencil"></i>
                      Edit
                    </div>
                  ) : (
                    <GuiTooltip tooltip="Edit Preferences" placement="right">
                      <div>
                        <i className="fa fa-pencil"></i>
                        Edit
                      </div>
                    </GuiTooltip>
                  )}
                </GuiButton>
              </div>

              <div className="section-body">
                <div>
                  <i className="fa fa-bell"></i>
                  <span className="section-body-title">
                    Text Notification Email
                  </span>
                </div>
                <span className="section-body-title">{notificationEmail}</span>
              </div>

              <div className="section-body">
                <div>
                  <i className="fa fa-volume-up"></i>
                  <span className="section-body-title">Voice</span>
                </div>
                <span className="section-body-title">{voice}</span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
