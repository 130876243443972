import "../styles/expand-panel.scss";
import React from "react";
import Resizer from "./resizer";


export default function ExpandPanel({ title, className, children, expanded, onHeaderClick, complete, hasError }) {
    return (
        <div className={"expand-panel " + (expanded ? "expanded " : "") + (complete && !expanded ? "complete " : "") + (className || "")}>
            <h4 onClick={onHeaderClick} className={hasError ? " invalid" : ""}>
                <i className="far fa-check-circle"></i>
                {title}
                <i className="edit-icon fas fa-pen"></i>
            </h4>
            <Resizer expanded={expanded}>
                {children}
            </Resizer>
        </div>
    );
}