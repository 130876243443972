export const storage = {
    get: get,
    getObject: getObject,
    set: set,
    setObject: setObject,
    remove: remove,
    getAndRemove: getAndRemove
}

function get(name) {
    let value = null;
    //try {
    //    value = sessionStorage.getItem(name);
    //} catch (e) { }

    //try {
    //    value = value || getCookie(name);
    //} catch (e) { }

    try {
        value = value || localStorage.getItem(name);
    } catch (e) { }
    return value;
}

function getObject(name) {
    let value = null;
    try {
        value = JSON.parse(localStorage.getItem(name));
    } catch (e) { }
    return value;
}

function set(name, value, longTerm) {
    if (value === null) {
        remove(name);
        return;
    }

    //try {
    //    sessionStorage.setItem(name, value);
    //} catch (e) { }

    //try {
    //    setCookie(name, value, longTerm ? 1000 : false);
    //} catch (e) { }

    try {
        localStorage.setItem(name, value);
    } catch (e) { }
}

function setObject(name, value) {
    try {
        localStorage.setItem(name, JSON.stringify(value));
    } catch (e) { }
}

function remove(name) {
    //try {
    //    sessionStorage.removeItem(name);
    //} catch (e) { }

    //try {
    //    eraseCookie(name);
    //} catch (e) { }

    try {
        localStorage.removeItem(name);
    } catch (e) { }
}

function getAndRemove(name) {
    const value = get(name);
    remove(name);
    return value;
}



//function getCookie(name) {
//    var nameEQ = encodeURIComponent(name) + "=";
//    var ca = document.cookie.split(';');
//    for (var i = 0; i < ca.length; i++) {
//        var c = ca[i];
//        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
//        if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
//    }
//    return null;
//}

//function eraseCookie(name) {
//    setCookie(name, "", -1);
//}

//function setCookie(name, value, days) {
//    var expires;
//    if (days) {
//        var date = new Date();
//        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
//        expires = "; expires=" + date.toGMTString();
//    } else {
//        expires = "";
//    }
//    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/; SameSite=None; Secure";
//}