import "../styles/add-person.scss";
import React, { useEffect, useState, useCallback } from "react";
import PageLayout from "../components/page-layout";
import actions from "../actions/actions";
import { useSelector } from "react-redux";
import Card from "../components/card";
import ExpandPanel from "../components/expand-panel";
import ValidationContext, {
  useValidation,
} from "../components/global-ui/validation";
import { GuiButton } from "../components/global-ui/gui-button";
import { GuiTextbox } from "../components/global-ui/gui-textbox";
import { useUpdateState } from "../utilities/utilities";
import { GuiSelect } from "../components/global-ui/gui-select";
import { Icon } from "../components/icon";
import navigate from "../utilities/navigation";
import { Path, USStates } from "../utilities/constants";
import { v4 as uuidv4 } from "uuid";

export default function AddPersonPage() {
  let userTypeOptions =
    useSelector((store) => actions.user.loadUserTypes()) || [];
  let isMobile = useSelector((store) => store.page.isMobile);
  let [step, setStep] = useState(0);
  let [contactNextisDisabled, setContactNextisDisabled] = useState(true);
  const [isAddUserButtonDisabled, setIsAddUserButtonDisabled] = useState(true);

  let [state, updateState] = useUpdateState({});

  let [person, updatePerson] = useUpdateState({
    password: "",
    firstName: "",
    lastName: "",
    userType: "",
    contactMethods: [
      {
        id: uuidv4(),
        contactType: 4,
        phone: "",
        description: "",
      },
    ],
  });

  function handleEmailErrorChange(hasError) {
    validateForm(hasError);
  }

  function validateForm(hasError) {
    const { password, firstName, lastName, userType } = person;
    if (
      !hasError &&
      password &&
      firstName &&
      lastName &&
      userType &&
      userName
    ) {
      setIsAddUserButtonDisabled(false);
    } else {
      setIsAddUserButtonDisabled(true);
    }
  }

  const [fieldErrors, setFieldErrors] = useState({
    firstName: false,
    lastName: false,
    password: false,
    username: false,
    userType: false,
  });

  const contactMethods = person.contactMethods || [];
  let phoneMethods = contactMethods.filter(
    (method) => method.contactType !== 4
  );
  let emailMethods = contactMethods.filter(
    (method) => method.contactType === 4
  );
  const [validEmailEntered, setValidEmailEntered] = useState(false);

  let customGroups = person.customGroups || [{ group_id: "" }];
  let groupOptions =
    useSelector((store) => actions.groups.loadGroupOptions()) || [];
  let [validUsername, setValidUsername] = useState(null);

  const [inputValue, setInputValue] = useState("");
  const [userName, setUserName] = useState("");

  const [timer, setTimer] = useState(null);

  const [isButtonDisabled, setButtonDisable] = useState(false);

  //password validation
  const [hasLength, setHasLength] = useState(false);

  const [hasUppercase, setHasUppercase] = useState(false);

  const [hasLowercase, setHasLowercase] = useState(false);

  const [hasNumber, setHasNumber] = useState(false);

  const [hasSpecialChar, setHasSpecialChar] = useState(false);

  let validation = useValidation(validate, []);
  let states = USStates;

  let step1Valid = false;
  let step2Valid = false;
  let step3Valid = false;

  const changeStep = useCallback((step) => {
    //if (step > 0 && !howTabValid)
    //    return;
    //if (step > 1 && !whoTabValid)
    //    return;
    //if (step > 2 && !messageTabValid)
    //    return;

    setStep(step);
  }, []); // dependencies

  function addPhoneMethod() {
    let newMethod = {
      id: uuidv4(),
      contactType: 1,
      phone: "",
      ext: "",
      description: "",
    };
    updatePerson("contactMethods", [...contactMethods, newMethod]);
  }

  function addEmailMethod() {
    let newMethod = {
      id: uuidv4(),
      contactType: 4,
      phone: "",
      description: "",
    };
    let newContactMethods = [...contactMethods, newMethod];
    updatePerson("contactMethods", newContactMethods);
  }

  function updateContact(index, name, value) {
    let updatedContactMethods = [...contactMethods];
    let updatedMethod = { ...updatedContactMethods[index], [name]: value };

    updatedContactMethods[index] = updatedMethod;

    if (name === "phone") {
      if (!value.includes("@") || value === "") {
        setValidEmailEntered(false);
      } else {
        setValidEmailEntered(true);
      }
    }
    updatePerson("contactMethods", updatedContactMethods);
  }

  function updateContactOption(cm, name, value) {
    let updatedContactMethods = contactMethods.map((method) =>
      method === cm ? { ...method, [name]: value.value } : method
    );
    updatePerson("contactMethods", updatedContactMethods);
  }

  function removePhoneContact(id) {
    const index = contactMethods.findIndex((method) => method.id === id);
    if (index !== -1) {
      const updatedMethods = contactMethods.filter((method, i) => i !== index);
      updatePerson("contactMethods", updatedMethods);
    }
  }

  function removeEmailContact(id) {
    const index = contactMethods.findIndex((method) => method.id === id);
    if (index !== -1) {
      const updatedMethods = contactMethods.filter((method, i) => i !== index);
      updatePerson("contactMethods", updatedMethods);
    }
  }

  function addGroup() {
    let groups = [...customGroups, { group_id: "" }];
    updatePerson("customGroups", groups);
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function updateGroup(index, value) {
    let groups = [...customGroups];
    groups[index] = { group_id: value };
    updatePerson("customGroups", groups);
  }

  function removeGroup(index) {
    let groups = customGroups.filter((x, i) => i !== index);
    updatePerson("customGroups", groups);
  }

  useEffect(() => {
    updatePerson((prevPerson) => ({ ...prevPerson, contactMethods }));
  }, [contactMethods]);

  function validate(name) {
    // console.log("name:", name);
    // return firstNameError;
    // firstNameError && setIsDisabled(false);
    // console.log("name:", name);
    // let errors = {};
    // return errors;
  }

  async function savePerson() {
    person = { ...person, userName: userName };
    // if (!validation.allValid()) return validation.setShow(true);
    updateState("saving", true);
    let result = await actions.people.savePerson(person);
    if (result && result.success) navigate(Path.People);
    else updateState("saving", false);
  }

  const handleUsernameChange = (name, value) => {
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value === "" ? true : false,
    }));

    clearTimeout(timer);
    setInputValue(value);

    if (value === "") {
      setValidUsername(null);
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        username: true,
      }));
      setButtonDisable(true);
    }
  };

  useEffect(() => {
    if (inputValue !== "") {
      setTimer(
        setTimeout(() => {
          validateUser(inputValue);
        }, 1000)
      );
    }
  }, [inputValue]);

  async function validateUser(username) {
    let result = await actions.people.validateUsername(username);
    if (result.success === true) {
      person = { ...person, username: inputValue };
      setUserName(inputValue);
      setValidUsername(true);
    } else {
      setValidUsername(false);
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        username: true,
      }));
      setButtonDisable(true);
    }
  }
  const handlePasswordChange = (name, value) => {
    setButtonDisable(true);
    updatePerson({ ...person, password: value });
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value === "" ? true : false,
    }));

    const lengthCheck = value.length >= 15;
    const uppercaseCheck = /[A-Z]/.test(value);
    const lowercaseCheck = /[a-z]/.test(value);
    const numberCheck = /[0-9]/.test(value);
    const specialCharCheck = /[\W]/.test(value);

    setHasLength(lengthCheck);
    setHasUppercase(uppercaseCheck);
    setHasLowercase(lowercaseCheck);
    setHasNumber(numberCheck);
    setHasSpecialChar(specialCharCheck);

    // Update button state based on all criteria being met
    const isPasswordValid =
      lengthCheck &&
      uppercaseCheck &&
      lowercaseCheck &&
      numberCheck &&
      specialCharCheck;

    !isPasswordValid &&
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        password: true,
      }));
  };

  const handleNextClick = () => {
    // Mark all the fields that are empty with alerts.
    person = { ...person, username: inputValue };
    Object.entries(person).forEach(([key, value]) => {
      if (value === "") {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [key]: true,
        }));
      }
    });

    // Check if all the feilds are valid and then navigate to next step.
    const isFormInValid = Object.values(person).some((value) => value === "");
    isFormInValid && setButtonDisable(true);
    !isFormInValid && changeStep(1);
  };

  const handleChange = (name, value) => {
    // Update the person object and set or remove alerts if value is empty or not for specific field.
    person = { ...person, username: inputValue };
    updatePerson(name, value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value === "" ? true : false,
    }));
  };
  useEffect(() => {
    person = { ...person, username: inputValue };
    switch (true) {
      case person.firstName !== "":
      case person.lastName !== "":
      case person.userType !== "":
      case person.password !== "":
      case person.username !== "":
        validateFieldError();
        break;

      default:
        // No action needed if none of the fields are non-empty
        break;
    }
  }, [fieldErrors]);

  async function validateFieldError() {
    const hasErrors = Object.values(fieldErrors).some(
      (value) => value === true
    );
    hasErrors ? setButtonDisable(true) : setButtonDisable(false);
  }

  return (
    <ValidationContext.Provider>
      <PageLayout title="Add User" className="add-person">
        <Card>
          {/* User Information */}
          <ExpandPanel
            title="User Information"
            expanded={step === 0}
            complete={step1Valid}
            onHeaderClick={() => changeStep(0)}
            hasError={validation.getDisplayError("tab0")}
          >
            <span className="required-info">*Required Information</span>
            <div className="row">
              <GuiTextbox
                label="First Name*"
                name="firstName"
                value={person.firstName}
                onChange={(name, value) => handleChange(name, value)}
                error={
                  fieldErrors.firstName && (
                    <span>
                      <i className="fas fa-exclamation-circle"></i> Required
                      Field
                    </span>
                  )
                }
              />
              <GuiTextbox
                label="Last Name*"
                name="lastName"
                value={person.lastName}
                onChange={(name, value) => handleChange(name, value)}
                error={
                  fieldErrors.lastName && (
                    <span>
                      <i className="fas fa-exclamation-circle"></i> Required
                      Field
                    </span>
                  )
                }
              />
            </div>
            <div className="row last">
              <GuiSelect
                label="User Type*"
                name="userType"
                value={person.userType}
                onChange={(name, option) => handleChange(name, option.value)}
                options={userTypeOptions}
                error={
                  fieldErrors.userType && (
                    <span>
                      <i className="fas fa-exclamation-circle"></i> Required
                      Field
                    </span>
                  )
                }
              />
              <GuiTextbox
                label="Unique ID"
                name="uniqueId"
                value={person.uniqueId}
                onChange={(name, value) => updatePerson(name, value)}
              />
            </div>
            <div className="row">
              <GuiTextbox
                label="Username*"
                name="username"
                value={inputValue}
                error={
                  fieldErrors.username && (
                    <span>
                      <i className="fas fa-exclamation-circle"></i> Required
                      Field
                    </span>
                  )
                }
                onChange={handleUsernameChange}
              />
              <div className="validate-user">
                {validUsername === true && (
                  <p className="valid-username">Valid Username!</p>
                )}
                {validUsername === false && (
                  <p className="username-exists">Username already exists!</p>
                )}
              </div>
            </div>

            <div className="user-helper-text">
              <p>Username must be unique.</p>
            </div>

            <GuiTextbox
              label="Password*"
              name="password"
              value={person.password}
              onChange={handlePasswordChange}
              error={
                fieldErrors.password && (
                  <span>
                    <i className="fas fa-exclamation-circle"></i> Required Field
                  </span>
                )
              }
            />
            <div className="password-validation">
              <div className="password-row">
                <div
                  style={{
                    color:
                      person.password !== ""
                        ? hasLength
                          ? "green"
                          : "red"
                        : "grey",
                  }}
                >
                  {person.password !== "" ? (hasLength ? "✓" : "✗") : "•"}15+
                  characters
                </div>
                <div
                  style={{
                    color:
                      person.password !== ""
                        ? hasUppercase
                          ? "green"
                          : "red"
                        : "grey",
                  }}
                >
                  {person.password !== "" ? (hasUppercase ? "✓" : "✗") : "•"} 1
                  uppercase
                </div>
                <div
                  style={{
                    color:
                      person.password !== ""
                        ? hasLowercase
                          ? "green"
                          : "red"
                        : "grey",
                  }}
                >
                  {person.password !== "" ? (hasLowercase ? "✓" : "✗") : "•"} 1
                  lowercase
                </div>
              </div>
              <div>
                <div
                  style={{
                    color:
                      person.password !== ""
                        ? hasNumber
                          ? "green"
                          : "red"
                        : "grey",
                  }}
                >
                  {person.password !== "" ? (hasNumber ? "✓" : "✗") : "•"} 1
                  number
                </div>
                <div
                  style={{
                    color:
                      person.password !== ""
                        ? hasSpecialChar
                          ? "green"
                          : "red"
                        : "grey",
                  }}
                >
                  {person.password !== "" ? (hasSpecialChar ? "✓" : "✗") : "•"}{" "}
                  1 special character(@#$%^*)
                </div>
              </div>
            </div>

            <div className="nxtBar">
              <GuiButton
                className="nxt-btn"
                onClick={handleNextClick}
                disabled={isButtonDisabled}
              >
                Next
              </GuiButton>
            </div>
          </ExpandPanel>

          {/* Contact Information */}
          <ExpandPanel
            title="Contact Information"
            expanded={step === 1}
            complete={step2Valid}
            onHeaderClick={() => changeStep(1)}
            hasError={validation.getDisplayError("tab0")}
          >
            <GuiTextbox
              className="address"
              label="Address"
              name="address"
              value={person.address}
              onChange={updatePerson}
            />

            <div className="row">
              <GuiTextbox
                label="City"
                name="city"
                value={person.city}
                onChange={updatePerson}
              />
              <GuiSelect
                label="State"
                name="state"
                value={person.state}
                onChange={(name, option) => updatePerson(name, option.value)}
                options={states}
              />
            </div>

            <GuiTextbox
              label="Zip"
              name="zip"
              value={person.zip}
              onChange={updatePerson}
            />

            {/*Email Address Section*/}
            <div className="section-title-email"> Email Addresses</div>
            {emailMethods.map((cm, i) => (
              <EmailContact
                key={i}
                method={cm}
                updateContact={updateContact}
                removeContact={removeEmailContact}
                index={i}
                contactMethods={contactMethods}
                setContactNextisDisabled={setContactNextisDisabled}
                onEmailErrorChange={handleEmailErrorChange}
              />
            ))}
            <GuiButton
              className="btn-outline add-btn"
              onClick={addEmailMethod}
              disabled={!validEmailEntered || emailMethods.length >= 4}
              title={
                emailMethods.length >= 4
                  ? "Maximum of 4 email addresses reached"
                  : "Please input one valid email address before adding additional email addresses"
              }
            >
              <Icon icon="plus" weight="400" />
              Email
            </GuiButton>

            {/*Phone Number Section*/}
            <div className="phone-number-section">
              <p className="section-title-phone"> Phone Number</p>
            </div>
            {phoneMethods.map((cm, i) => {
              return (
                <ContactMethod
                  key={cm.id}
                  method={cm}
                  updateContact={updateContact}
                  removeContact={removePhoneContact}
                  index={i}
                  contactMethods={contactMethods}
                  updateContactOption={updateContactOption}
                />
              );
            })}
            <GuiButton
              className="btn-outline add-btn"
              onClick={addPhoneMethod}
              disabled={phoneMethods.length >= 4}
              title={
                phoneMethods.length >= 4
                  ? "Maximum of 4 phone numbers reached"
                  : ""
              }
            >
              <Icon icon="plus" className="mt-3" weight="400" />
              Add Phone
            </GuiButton>

            <div className="nxtBar">
              <GuiButton
                className="nxt-btn"
                onClick={() => changeStep(2)}
                disabled={contactNextisDisabled}
              >
                Next
              </GuiButton>
            </div>
          </ExpandPanel>

          {/* Group Information */}
          <ExpandPanel
            title="Group Information"
            expanded={step === 2}
            complete={step3Valid}
            onHeaderClick={() => changeStep(2)}
            hasError={validation.getDisplayError("tab0")}
          >
            {customGroups.map((id, i) => (
              <CustomGroup
                groupOptions={groupOptions}
                groupIds={customGroups}
                updateGroup={updateGroup}
                removeGroup={removeGroup}
                index={i}
              />
            ))}
            <GuiButton className="btn-outline add-btn" onClick={addGroup}>
              <Icon icon="plus" weight="400" />
              Add
            </GuiButton>
          </ExpandPanel>
        </Card>

        {/* Bottom Buttons */}
        <div className="bottom-button-bar">
          <GuiButton
            className="btn-outline"
            onClick={() => navigate(Path.People)}
          >
            Cancel
          </GuiButton>
          <GuiButton
            className="last-btn"
            onClick={savePerson}
            disabled={isAddUserButtonDisabled}
          >
            Add User
          </GuiButton>
        </div>
      </PageLayout>
    </ValidationContext.Provider>
  );
}

const ContactMethod = React.memo(function ContactMethod({
  method,
  updateContact,
  removeContact,
  index,
  contactMethods,
  updateContactOption,
}) {
  const phoneOptions = [
    { value: 3, label: "Cell" },
    { value: 2, label: "Work" },
    { value: 1, label: "Home" },
  ];

  function update(name, value) {
    const index = contactMethods.findIndex((m) => m === method);
    updateContact(index, name, value);
  }

  function updateOption(name, value) {
    updateContactOption(method, name, value);
  }
  return (
    <div className="contact-method removable-section">
      <div className="row">
        <GuiSelect
          placeholder="Type"
          name="contactType"
          className="contact-select"
          value={method.contactType}
          onChange={updateOption}
          options={phoneOptions}
        />
        <GuiTextbox
          className="phone"
          label="Phone Number"
          name="phone"
          value={method.phone}
          onChange={(name, value) => update(name, value)}
        />
        <GuiTextbox
          className="ext"
          label="Ext"
          name="ext"
          value={method.ext}
          onChange={(name, value) => update(name, value)}
        />
        <div className="d-flex">
          <GuiTextbox
            className="phone-description"
            label="Description"
            name="description"
            value={method.description}
            onChange={(name, value) => update(name, value)}
          />
          <Icon icon="xmark-large" onClick={() => removeContact(method.id)} />
        </div>
      </div>
    </div>
  );
});

function EmailContact({
  method,
  updateContact,
  removeContact,
  index,
  contactMethods,
  setContactNextisDisabled,
  onEmailErrorChange,
}) {
  const [emailError, setEmailError] = useState(false);

  function update(name, value) {
    const index = contactMethods.findIndex((m) => m === method);
    updateContact(index, name, value);

    if (name === "phone") {
      if (!value.includes("@") || value === "") {
        setEmailError(true);
        onEmailErrorChange(true);
      } else {
        setEmailError(false);
        setContactNextisDisabled(false);
        onEmailErrorChange(false);
      }
    }
  }

  return (
    <div className="contact-method removable-section">
      <div className="row">
        <GuiTextbox
          className="email-input-box"
          label="Email"
          name="phone"
          value={method.phone}
          onChange={update}
          error={
            emailError && (
              <span>
                <i className="fas fa-exclamation-circle"></i> Required field
              </span>
            )
          }
        />
        <div className="d-flex">
          <GuiTextbox
            label="Description"
            name="description"
            value={method.description}
            onChange={update}
          />
          {index !== 0 && (
            <Icon icon="xmark-large" onClick={() => removeContact(method.id)} />
          )}
        </div>
      </div>
    </div>
  );
}

function CustomGroup({
  groupIds,
  updateGroup,
  removeGroup,
  index,
  groupOptions,
}) {
  let groupId = groupIds[index].group_id;
  groupOptions = groupOptions.filter(
    (g) => g.value === groupId || !groupIds.includes(g.value)
  );

  return (
    <div className="custom-group removable-section">
      <p className="section-title">Custom Group {index + 1}</p>
      <div className="d-flex mt-3">
        <GuiSelect
          label=""
          name="group"
          value={groupId}
          onChange={(name, option) => updateGroup(index, option.value)}
          options={groupOptions}
        />
        <Icon icon="xmark-large" onClick={() => removeGroup(index)} />
      </div>
    </div>
  );
}
