import '../styles/modal.scss';
import React, { useState} from "react";
import { dispatch } from '../redux/redux-config';
import { useSelector } from 'react-redux';


export function ToastContainer() {
    let toasts = useSelector(store => store.page.toasts);

    function close(toast) { 
        dispatch("REMOVE_TOAST", toast);
    }

    return (
        <div className="toast-container">
            {toasts.map((t, index) =>
                <div key={index} className={"toast " + t.status}>
                    <i className={"toast-icon fal " + (t.status === "success" ? "fa-check" : "fa-times-circle")}></i>
                    <span className="toast-text">{t.message}</span>
                    <i className="fal fa-times" onClick={e => close(t)}></i>
                </div>
            )}
        </div>
    );

}

// Status on toast to change styling is either "success", "removed", or "error"

export function showToast(status, message) {
    let toast = { status: status, message: message };
    dispatch("ADD_TOAST", toast);
    setTimeout(() => dispatch("REMOVE_TOAST", toast), 8000);
}

