import '../styles/help.scss';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from '../api/api';
import { GuiButton } from '../components/global-ui/gui-button';
import navigate from '../utilities/navigation';
import { isInstalled } from '../utilities/utilities';


export default function Help() {
    let isMobile = useSelector(store => store.page.isMobile);
    let [updateAvailable, setUpdateAvailable] = useState(false);

    useEffect(() => {
        async function checkVersion() {
            var result = await api.authentication.getVersion();
            if (result.success && result.version !== window.hgsVersion)
                setUpdateAvailable(true);
        }
        checkVersion(); // useEffect doesn't like async
    }, []);

    async function update() {
        if (navigator.serviceWorker) {
            const unregisterPromises = (await navigator.serviceWorker.getRegistrations()).map(r => r.unregister());
            await Promise.all(unregisterPromises);

            //navigator.serviceWorker.getRegistrations().then(function (registrations) {
            //    for (let registration of registrations) {
            //        registration.unregister()
            //            .then(function () { return self.clients.matchAll(); })
            //            .then(function (clients) {
            //                clients.forEach(client => {
            //                    if (client.url && "navigate" in client) {
            //                        client.navigate(client.url)
            //                    }
            //                });
            //            });
            //    }
            //});
        }
        window.location.reload();
    }

    return (
        <div>
            <div className="page-title">
                <span className="nav-button" onClick={() => navigate("/")}><i id="back" className="far fa-chevron-left"></i>{isMobile ? "" : "Back"}</span>
                Help
            </div>
            <div className="help-page">
                <div className="container-card">
                    <div><img src="/lib/images/mcc.png" /></div>
                    <div className="address-text">is brought to you by</div>
                    <div className="team-title">High Ground Solutions, Inc.</div>
                    <div className="address-text">PO Box 26466</div>
                    <div className="address-text">Birmingham, AL 35260-0466</div>
                    <div className="support-text">For app support, contact us toll-free</div>
                    <div className="support-link" ><a href="tel:8889885884">888-988-5884</a></div>
                    <div className="support-text">or via email</div>
                    <div className="support-link" ><a href="mailto:support@highgroundsolutions.com">support@highgroundsolutions.com</a></div>
                    <div className="update-section">
                        <span>{isInstalled() ? "Installed" : "Web"} Version: {window.hgsVersion}</span>
                        {updateAvailable &&
                            <GuiButton dataQa="update" onClick={update}>Update</GuiButton>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}