import React, { useState } from "react";
import "../styles/message-dropdown.scss";
import actions from "../actions/actions";
import { GuiButton } from "../components/global-ui/gui-button";

const MessageDropdown = ({ isEditing }) => {
  const [isOpen, setIsOpen] = useState(false);
  const blankAlert = {
    name: "",
    isScheduled: false,
    subject: "",
    htmlMessage: "",
    allGroups: false,
    groups: [],
    isEmergency: false,
    attachments: [],
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (type) => {
    setIsOpen(false);

    switch (type) {
      case "text":
        actions.alerts.showEditAlert({ ...blankAlert, alertType: 3 });

        break;
      case "email":
        actions.alerts.showEditAlert({ ...blankAlert, alertType: 2 });

        break;
      case "voice":
        actions.alerts.showEditAlert({ ...blankAlert, alertType: 1 });

        break;
      default:
        break;
    }
  };

  return (
    <div className="message-dropdown">
      <GuiButton
        className="dropdown-button"
        onClick={handleToggle}
        disabled={isEditing}
      >
        New Message <i className="fa fa-chevron-down"></i>
      </GuiButton>
      {isOpen && (
        <div className="dropdown-menu">
          <div className="dropdown-item" onClick={() => handleSelect("text")}>
            <i className="fa fa-mobile"></i> <span>Text</span>
          </div>
          <div className="dropdown-item" onClick={() => handleSelect("email")}>
            <i className="fa fa-envelope"></i> <span>Email</span>
          </div>
          <div className="dropdown-item" onClick={() => handleSelect("voice")}>
            <i className="fa fa-microphone"></i> <span>Voice</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageDropdown;
