import '../styles/search-box.scss';
import React, { useEffect, useRef } from "react";


export function SearchBox({ onSearchChange, searching, minLength }) {
    let sync = useRef({}).current;

    useEffect(() => {
        if (!searching && sync.text !== sync.lastSearch)
            doSearch(sync.text);
    }, [searching]);

    function onChange(e) {
        let text = e.currentTarget.value;
        let shouldSearch = !searching && (!minLength || text.length >= minLength || sync.text?.length >= minLength); // Must be at least minLength or have a previous search

        sync.text = text;
        if (shouldSearch)
            doSearch(sync.text);
    }

    function doSearch(text) {
        sync.lastSearch = text;
        onSearchChange(text);
    }

    return (
        <label className="search-box">
            <i className="fal fa-search"></i>
            <input name="search" placeholder="Search" onChange={onChange} data-qa="search" />
        </label>
    );
}

