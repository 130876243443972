import { appState, dispatch } from "../redux/redux-config";
import { MobileWidth } from "../utilities/constants";


const page = {
    toggleSideBar: function (isExpanded) {
        dispatch("TOGGLE_SIDE_BAR", isExpanded);
    }
}
export default page;






export function pageReducer(state, action) {
    if (state === undefined)
        return initialState;


    switch (action.type) {
        case 'TOGGLE_SIDE_BAR':
            localStorage.setItem("side-bar-collapsed", action.payload ? "expanded" : "collpased"); // Save their choice
            return {
                ...state,
                sidebarExpanded: action.payload
            }

        case 'IS_MOBILE_CHANGED':
            return {
                ...state,
                isMobile: action.payload
            }
        case 'IS_ALERT_SELECTED':
            return {
                ...state,
                isAlertSelected: action.payload
            }
        case 'SWITCH_LOGIN_SCREEN':
            return {
                ...state,
                switchLoginScreen: action.payload

            }
        case 'ADD_TOAST':
            return {
                ...state,
                toasts: [...state.toasts, action.payload]
            }
        case 'REMOVE_TOAST':
            return {
                ...state,
                toasts: state.toasts.filter(t => t !== action.payload)
            }

        case 'UPDATE_AVAILABLE':
            return {
                ...state,
                updateVersion: action.payload
            }

        case 'LOGOUT':
            return { ...initialState, toasts: state.toasts };

        default:
            return state;
    }
}

const initialState = {
    sidebarExpanded: (window.innerWidth >= MobileWidth) && localStorage.getItem("side-bar-collapsed") !== "collpased",
    isMobile: (window.innerWidth <= MobileWidth),
    toasts: []
};