import { useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { store, routerHistory } from '../redux/redux-config';
import { useEffect } from 'react';


export default function navigate(relativeUrl) {
    //if (nav.warningCallback) {
    //    nav.next = relativeUrl;
    //    nav.allow = function () {
    //        nav.warningCallback = null;
    //        navigate(relativeUrl);
    //    };
    //    nav.warningCallback(nav);
    //}

    if (routerHistory.location?.state?.temporary)
        routerHistory.replace({ pathname: relativeUrl });
    else
        store.dispatch(push(relativeUrl));
}

export function useNavState(selector) {
    return useSelector(store => selector(store.router.location?.state || {}));
}

export function pushNavState(state) {
    routerHistory.push({ state: { ...state, temporary: true } });
}

export function popNavState() {
    if (routerHistory.location?.state?.temporary)
        routerHistory.goBack();
}


// Don't let the forward or back button bring up a temporary state
setTimeout(() => {
    routerHistory.listen(function (e, type) {
        if (type === "POP" && e.state?.temporary) {
            routerHistory.replace(e.pathname, undefined);
            e.state = undefined;
        }
    });
}, 0);


//const nav = { warningCallback: null };
//export function useNvigationWarning(callback) {
//    nav.warningCallback = callback;

//    useEffect(() => {
//        return () => {
//            nav.warningCallback = null;
//        };
//    }, []);

//    return nav;
//}