import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { connectRouter, routerMiddleware, push } from "connected-react-router";
import { createBrowserHistory } from "history";
import { alertReducer } from "../actions/alert-actions";
import { trackReducer } from "../actions/track-actions";
import { pageReducer } from "../actions/page-actions";
import { userReducer } from "../actions/user-actions";
import { filesReducer } from "../actions/file-actions";
import { peopleReducer } from "../actions/people-actions";
import { groupsReducer } from "../actions/group-actions";

export const routerHistory = createBrowserHistory({ basename: "/" });
export const store = configureStore(routerHistory);

function configureStore(routerHistory, initialState) {
  const middleware = [thunk, routerMiddleware(routerHistory)];

  const reducers = {
    alert: alertReducer,
    files: filesReducer,
    groups: groupsReducer,
    overview: trackReducer,
    page: pageReducer,
    people: peopleReducer,
    user: userReducer,
    router: connectRouter(routerHistory),
  };

  const enhancers = [];
  const windowIfDefined = typeof window === "undefined" ? null : window;
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }

  return createStore(
    combineReducers(reducers),
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}

export function dispatch(type, payload, delay) {
  if (delay) setTimeout(() => store.dispatch({ type: type, payload: payload }));
  else store.dispatch({ type: type, payload: payload });
}

export function appState() {
  return store.getState();
}
