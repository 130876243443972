import "../../styles/global-ui/gui-checkbox.scss";
import React, { useContext } from "react";
//import ThemeContext from '../contexts/themze-context';
//import ValidationContext from './validation';
//import Error from "./error";



export function GuiCheckbox({ name, label, value, onChange, disabled, className, onClick }) {

    return (
        <label className={"gui-checkbox " + (className || "") + (disabled ? " disabled" : "")} onClick={onClick}>
            <input type="checkbox" name={name} checked={value || false} onChange={e => onChange && onChange(name, e.target.checked)} disabled={disabled} />
            <i className="fa fa-check box"></i>
            {label && <span>{label}</span>}
        </label>
    );
}



//export function GuiCheckbox({ name, label, value, onChange, disabled, className, style, error }) {
//    let validation = useContext(ValidationContext);
//    error = error || (validation && validation.show && validation.getError(name));

//    return (
//        <div className={"gui-checkbox-2 " + (className || "") + (error ? " has-error" : "") + (value ? " checked" : "")}>
//            <div className="checkbox">
//                <label className="control-label">
//                    <input name={name} checked={value || false} onChange={e => onChange ? onChange(name, e.target.checked) : undefined} type="checkbox" disabled={disabled || false} style={style} />
//                    <i className="fas fa-check"></i>
//                    <span>{label}</span>
//                </label>
//            </div>
//            <Error error={error} />
//        </div>
//    );
//}


export function CheckBoxHeaderButton({ checked, onChange, onClick }) {
    return (
        <label className="gui-checkbox grid-select-all" >
            <input type="checkbox" checked={checked || false} onChange={onChange} onClick={onClick} />
            <i className="fa fa-dash box"></i>
        </label>
    );
}