import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Button } from "react-bootstrap";
import "../styles/comm-gui-dropdown.scss";

const VoiceDropdown = ({ onChange, optionType, typeOptions }) => {
  const [isOpen, setIsOpen] = useState(false);
  let filteredOption = typeOptions.filter((i) => {
    return i.value === optionType;
  });
  let selectedOptionType = filteredOption[0].value;
  const handleSelect = (value) => {
    setIsOpen(false);
    onChange("optionType", value);
  };

  return (
    <Dropdown
      className="dropdown"
      show={isOpen}
      onToggle={(isOpen) => setIsOpen(isOpen)}
    >
      <Dropdown.Toggle variant="success" className="dropdown-toggle-comm">
        {optionType === selectedOptionType && filteredOption[0].label}
        {isOpen ? (
          <svg className="arrow" width="20" height="16" viewBox="0 0 40 40">
            <path
              d="M 5 30 L 20 15 L 35 30"
              stroke="#6B6B6B"
              fill="transparent"
              strokeWidth="3"
            />
          </svg>
        ) : (
          <svg className="arrow" width="20" height="16" viewBox="0 0 40 40">
            <path
              d="M 5 10 L 20 25 L 35 10"
              stroke="#6B6B6B"
              fill="transparent"
              strokeWidth="3"
            />
          </svg>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className="dropdown-container-comm">
            {typeOptions.map((item) => (
              <Button
                key={item.value}
                className="all-communication-btn"
                onClick={() => handleSelect(item.value)}
              >
                {item.label}
              </Button>
            ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default VoiceDropdown;
