import '../styles/confirm-close-modal.scss';
import { Modal } from './modal';
import React from 'react';


export function ConfirmCloseModal({ onDelete, onSaveDraft, onCancel, showConfirmDelete}) {

    return (
        <Modal className="confirm-close-modal">
            {showConfirmDelete && <>
                <h4>
                    Are you sure you want to do this?
                </h4>
                <div className="detail-delete" onClick={onDelete}>Delete Alert</div>
            </>}
            {!showConfirmDelete && <>
                <h4>
                    Are you sure you want to discard your Alert?<br />
                    All unsaved changes will be lost?
                </h4>
                <div id="discard" className="delete" onClick={onDelete}>Discard Alert</div>
                <div id="save-draft" className="save-draft" onClick={onSaveDraft}>Save Draft</div>
            </>}
            <div className="cancel" onClick={onCancel}>Cancel</div>
        </Modal>
    );
}

