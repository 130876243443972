import "../styles/grid.scss";
import "../styles/pager.scss";
import React, { useRef, useEffect } from "react";
import { useUpdateState } from "../utilities/utilities";
import LoadingSpinner from "./loading-spinner";
import Popover from "./popover";
import { useSelector } from "react-redux";


export default function Grid({ groupDetails, header, itemTemplate, mobileItemTemplate, onItemsNeeded, loadedItemsRef, onPageChanged, mobileFooter, mobileItemHeight, reset, search, gridReset }) {
    let isMobile = useSelector(store => store.page.isMobile);
    let [state, updateState] = useUpdateState({ page: 1, pageSize: isMobile ? Math.ceil(window.innerHeight / mobileItemHeight * 2) : 10, totalCount: 0 });
    let sync = useRef({}).current;
    let divRef = useRef();

    let { page, pageSize, totalCount } = state;

    // Calculate page count
    let lastPage = Math.ceil((totalCount || 0) / pageSize);
    if (!lastPage)
        lastPage = 1;
    if (page > lastPage)
        page = lastPage;

    // Showing Text
    let showingText = "Showing ";
    if (totalCount > 0) {
        showingText += Math.min(page * pageSize - pageSize + 1, totalCount);
        showingText += "-";
        showingText += Math.min(page * pageSize, totalCount);
        showingText += " of " + totalCount;
    }
    else if (sync.reloading)
        showingText = "";
    else
        showingText += "0 of 0";
    
    
    useEffect(reload, [reset, pageSize, search, gridReset]);

    useEffect(() => {
      reload();
    }, [groupDetails]);

    function reload() {
        sync.pages = {};
        if (loadedItemsRef)
            loadedItemsRef.current = [];

        sync.reloading = true;
        updateState({});
        loadPage(1, false);
    }

    function loadPage(page, skipTotal) {
        if (sync.loading)
            return;

        sync.loading = true;
        onItemsNeeded(page, pageSize, skipTotal)
            .then(result => {
                sync.loading = false;
                sync.reloading = false;

                sync.pages[page] = result.list;
                sync.totalCount = (skipTotal ? sync.totalCount : result.totalCount);
                if (loadedItemsRef) {
                    Array.prototype.push.apply(loadedItemsRef.current, result.list);
                }

                if (isMobile) {
                    onScroll();
                } else {
                    updateState({ list: sync.pages[page], page: page, totalCount: (skipTotal ? state.totalCount : result.totalCount) });
                    if (onPageChanged)
                        onPageChanged(sync.pages[page]);
                }
            })
            .catch(error => {
              console.error('Error in onItemsNeeded:', error);
            });
    }

    function changePage(e) {
        let newPage = parseInt(e.currentTarget.getAttribute("data-page"));
        if (!sync.pages[newPage]) {
            loadPage(newPage, true);
        }
        else {
            updateState({ list: sync.pages[newPage], page: newPage });
            if (onPageChanged)
                onPageChanged(sync.pages[newPage]);
        }
    }

    function changePageSize(size) {
        updateState({ pageSize: size });
    }


    let scrollStyle = undefined;
    if (isMobile) {
        scrollStyle = {
            height: state.scrollHeight,
            paddingTop: state.scrollOffset
        };
    }

    function onScroll() {
        if (!isMobile)
            return;

        let scrollTop = divRef.current.scrollTop;
        let height = divRef.current.offsetHeight;

        let topPage = Math.ceil(scrollTop / mobileItemHeight / pageSize);
        let bottomPage = Math.ceil((scrollTop + height) / mobileItemHeight / pageSize);
        topPage = Math.max(1, topPage);
        bottomPage = Math.max(1, bottomPage);

        // Total height
        let scrollHeight = (sync.totalCount || 0) * mobileItemHeight;

        // Padding at top
        let offset = 0;
        for (let num = 1; num < topPage; num++) {
            offset += (sync.pages[num] ? sync.pages[num].length : pageSize) * mobileItemHeight; // Pages might have uneven sizes (ex "All Users" adds an item to the first page)
        }

        // Visible items
        let list = [];
        for (let num = topPage; num <= bottomPage; num++) {
            if (!sync.pages[num]) {
                loadPage(num, true);
                break;
            }

            list = list.concat(sync.pages[num]);
        }

        updateState({
            list: list,
            scrollHeight: scrollHeight + "px",
            scrollOffset: offset + "px"
        });
    }

    // Check if switching from infinite scroll back to paging
    if (sync.isMobile && !isMobile)
        reload(); // page sizes are different, so reload (future: attempt to re-partition pages, or keep a separate page list for mobile)
    sync.isMobile = isMobile;

    return (
        <div className="grid" onScroll={onScroll} ref={divRef}>
            {/* Header */}
            {header && !isMobile &&
                <div className="grid-header">
                    {header}
                </div>
            }

            {/* Rows */}
            <div className="items" style={scrollStyle}>
                {state.list && (isMobile && mobileItemTemplate ? state.list.map(g => mobileItemTemplate(g)) : state.list.map(g => itemTemplate(g)))}
                <LoadingSpinner show={sync.loading || sync.reloading} className={state.list && state.list.length > 0 ? "" : "no-items"} />
            </div>

            {/* Paging */}
            {isMobile ?
                <div className="grid-mobile-footer">
                    {mobileFooter && mobileFooter(sync.totalCount)}
                </div>
                :
                <div className="pager">
                    {page > 2 &&
                        <span data-page={1} onClick={changePage}><i className="far fa-chevron-left"></i></span>
                    }

                    {page > 1 &&
                        <span data-page={page - 1} onClick={changePage}>{page - 1}</span>
                    }

                    <span className="active">{page}</span>

                    {page < lastPage &&
                        <span data-page={page + 1} onClick={changePage}>{page + 1}</span>
                    }

                    {page < lastPage - 2 &&
                        <>
                            ...
                            <span data-page={lastPage} onClick={changePage}>{lastPage}</span>
                        </>
                    }

                    {page < lastPage - 1 &&
                        <span data-page={lastPage} onClick={changePage}><i className="far fa-chevron-right"></i></span>
                    }

                    <div className="page-size" onClick={() => updateState({ showUserMenu: !state.showUserMenu })}>
                        <label>Show:</label> {pageSize} <i className="far fa-chevron-down"></i>

                        <Popover show={state.showUserMenu} className="drop-up no-arrow" onHide={() => updateState({ showUserMenu: false })}>
                            <div className="menu-item" onClick={e => changePageSize(10)}>10</div>
                            <div className="menu-item" onClick={e => changePageSize(25)}>25</div>
                            <div className="menu-item" onClick={e => changePageSize(50)}>50</div>
                            <div className="menu-item" onClick={e => changePageSize(100)}>100</div>
                        </Popover>
                    </div>

                    <div className="showing-text">{showingText}</div>
                </div>
            }
        </div>
    );
}

export function ColumnHeader({ className, children, sort, onSort, show, visibility }) {
    if (show === false || (visibility && !visibility[className]))
        return null;

    let sortDir = sort && sort[className];
    let sortClass = (sortDir === true ? " sorted asc" : sortDir === false ? " sorted desc" : "");

    return (
        <span className={className + sortClass + (onSort ? " sortable" : "")} onClick={e => onSort && onSort(className)}>{children}</span>
    );
}


export function GridCell({ className, children, show, visibility }) {
    if (show === false || (visibility && !visibility[className]))
        return null;

    return (
        <span className={className}>{children}</span>
    );
}