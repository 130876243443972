import React from "react";
import "../styles/add-contact-popup.scss";
import { useSelector } from "react-redux";
import { GuiButton } from "../components/global-ui/gui-button";
import { SettingsApi } from "../actions/settings-action";
import { showToast } from "../components/toast";

const DeleteContactPopup = ({
  deleteModal,
  onClose,
  deletedContact,
  onDeleteSuccess,
}) => {
  let isMobile = useSelector((store) => store.page.isMobile);
  async function onDeleteContact() {
    const response = await (deletedContact.contactTypeName === "Address"
      ? SettingsApi.clearAddress(deletedContact.id)
      : SettingsApi.deleteContact([deletedContact.id]));
    if (response.success) {
      showToast("success", "Contact Deleted");
      onDeleteSuccess();
    }
  }
  return (
    <div className={`Modal ${deleteModal ? "Show" : "hide"}`}>
      <>
        <div className="modal-header">
          {isMobile && (
            <span onClick={onClose} className="mr-2">
              <i className="fa fa-chevron-left close-primary-icon cursor-pointer"></i>
            </span>
          )}
          <h4>Confirm Delete</h4>
          {!isMobile && (
            <span onClick={onClose}>
              <i className="fa fa-close close-primary-icon cursor-pointer"></i>
            </span>
          )}
        </div>
        <div className="modal-body-delete">
          <div className="delete-header">
            {`Are you sure you want to delete this ${deletedContact.contactTypeName} contact? `}
          </div>
          <div className="delete-title">
            This will delete this contact permanently. You cannot undo this
            action.
          </div>
        </div>
        <div className="modal-footer-btns">
          <GuiButton className="cancel-btn" onClick={onClose}>
            Cancel
          </GuiButton>
          <GuiButton className="delete-button " onClick={onDeleteContact}>
            <span>Delete</span>
          </GuiButton>
        </div>
      </>
    </div>
  );
};
export default DeleteContactPopup;
