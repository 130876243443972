import '../styles/tag.scss';
import React from "react";
import { Icon } from "./icon";


export function Tag({ className, label, onRemove }) {
    return (
        <span className={"tag " + (className || "")}>{label}{onRemove && <Icon icon="times" onClick={onRemove} />}</span>
    );
}

