import "../styles/login.scss";
import React, { useEffect, useState } from "react";
import { dispatch, routerHistory } from "../redux/redux-config";
import actions from "../actions/actions";
import { useSelector } from "react-redux";
import { GuiTextbox } from "../components/global-ui/gui-textbox";
import { isInstalled, useUpdateState } from "../utilities/utilities";
import { ErrorMessage } from "../components/error-message";
import { Spinner } from "../components/loading-spinner";
import { ToastContainer } from "../components/toast";
import { GuiButton } from "../components/global-ui/gui-button.jsx";
import user from "../actions/user-actions.js";
import CircularProgress from "../actions/CircularProgress.js";

export default function LoginPage() {
  const [isSSOLogin, setSSOLogin] = useState(true);
  let installed = isInstalled() || undefined;
  let switchLoginScreen = useSelector((store) => store.user.switchLoginScreen);

  useEffect(() => {
    if (
      window.location.pathname === "/" &&
      window.location.search.startsWith("?jwt=")
    ) {
      const queryParams = new URLSearchParams(routerHistory.location.search);
      const jwtToken = queryParams.get("jwt");
      if (jwtToken) {
        dispatch("LOGIN_STARTED", true);
        amplifyLogin(jwtToken);
      }
    } else {
      setSSOLogin(false);
      document.body.classList.add("login-page");
      return () => document.body.classList.remove("login-page");
    }
  }, []);

  if (switchLoginScreen === "forgotPassword") return <ForgotPassword />;
  if (switchLoginScreen === "verificationCode") return <VerificationCode />;
  if (switchLoginScreen === "resetPassword") return <ResetPassword />;
  else {
    return isSSOLogin ? <CircularProgress /> : <SignInPage />;
  }
  async function amplifyLogin(jwtToken) {
    const payload = { unityJwt: jwtToken };
    const result = await user.amplifyLogin(payload);
    if (result.success) {
      routerHistory.replace("/");
      dispatch("LOAD_USER", result);
      if (installed) localStorage.setItem("session", result.jwt);
      else sessionStorage.setItem("session", result.jwt);
    } else {
      document.body.classList.add("login-page");
      setSSOLogin(false);
      routerHistory.replace("/");
      dispatch("LOGIN_ERROR", result);
      return () => document.body.classList.remove("login-page");
    }
  }
}

function SignInPage() {
  let { loggingIn } = useSelector((store) => store.user);
  let [state, update] = useUpdateState({});

  function updateValue(name, value) {
    update({ [name]: value });
  }

  function login() {
    if (loggingIn) return;
    actions.user.login(state.username, state.password);
  }

  return (
    <div className="login-screen">
      <div className="image">
        <img src="/lib/images/pwa-spash.svg" alt="" />
      </div>
      <h2 className="sign-in-title">Sign In</h2>

      <GuiTextbox
        label="Username"
        name="username"
        value={state.username}
        onChange={updateValue}
        dataQa="username"
      />
      <GuiTextbox
        label="Password"
        name="password"
        value={state.password}
        onChange={updateValue}
        dataQa="password"
        className="password"
        type="password"
        onKeyUp={(e) => e.keyCode === 13 && login()}
      />

      <GuiButton onClick={login} disabled={loggingIn} dataQa="login">
        {loggingIn ? <Spinner /> : "Login"}
      </GuiButton>
      <div
        className="link"
        onClick={() => actions.user.changeScreen("forgotPassword")}
        data-qa="forgotPassword"
      >
        Forgot password
      </div>
      <ToastContainer />
    </div>
  );
}

export function ForgotPassword() {
  let { loggingIn } = useSelector((store) => store.user);
  let [state, update] = useUpdateState({});

  function updateValue(name, value) {
    update({ [name]: value });
  }

  return (
    <div className="login-screen">
      <div>
        <span
          className="nav-button"
          onClick={() => actions.user.changeScreen("login")}
        >
          <i className="far fa-chevron-left"></i>Back
        </span>
      </div>
      <h2>Forget your password?</h2>
      <div className="text">
        Enter the username associated with your account and we'll send you a
        link to reset your password
      </div>

      <GuiTextbox
        label="Username"
        name="username"
        value={state.username}
        onChange={updateValue}
        dataQa="username"
      />

      <GuiButton
        onClick={(e) => actions.user.forgot(state.username)}
        disabled={loggingIn}
        dataQa="reset-instructions"
      >
        {loggingIn ? <Spinner /> : "Send Reset Instructions"}
      </GuiButton>
      <ToastContainer />
    </div>
  );
}

export function VerificationCode() {
  let { loggingIn } = useSelector((store) => store.user);
  let [state, update] = useUpdateState({});

  function updateValue(name, value) {
    update({ [name]: value });
  }

  return (
    <div className="login-screen">
      <h2>Enter verification code</h2>
      <div className="text">
        You should receive a verification code in your email
      </div>

      <GuiTextbox
        label="Verification Code"
        name="code"
        value={state.code}
        onChange={updateValue}
        className="code"
        dataQa="verification-code"
      />

      <GuiButton
        onClick={(e) => actions.user.verify(state.code)}
        disabled={loggingIn}
        dataQa="next"
      >
        {loggingIn ? <Spinner /> : "Next"}
      </GuiButton>
      <div className="verification-footer">
        Did not receive the email? Check your spam filter or{" "}
        <a onClick={() => actions.user.changeScreen("forgotPassword")}>
          try another username
        </a>
      </div>
      <ToastContainer />
    </div>
  );
}

export function ResetPassword() {
  let { loggingIn } = useSelector((store) => store.user);
  let [state, update] = useUpdateState({});

  if (
    window.location.pathname === "/reset" &&
    window.location.search.startsWith("?ID=")
  ) {
    dispatch("LOGIN_SETTINGS", { resetId: window.location.search.substr(4) });
    window.history.replaceState({}, "", "/");
  }

  function updateValue(name, value) {
    update({ [name]: value, error: null });
  }

  function save() {
    if (loggingIn) return;

    if (!state.password) {
      update("error", "Please enter a password.");
      return;
    }

    if (state.password.length < 10) {
      update("error", "Password must be at least 10 characters.");
      return;
    }

    if (state.password !== state.password2) {
      update("error", "Password and confirm password must match.");
      return;
    }

    actions.user.resetPassword(state.password);
  }

  return (
    <div className="login-screen">
      <div>
        <span
          className="nav-button"
          onClick={() => actions.user.changeScreen("login")}
        >
          <i className="far fa-chevron-left"></i>Back
        </span>
      </div>
      <h2>Create new password</h2>
      <GuiTextbox
        className={state.error ? "has-error" : ""}
        label="Password"
        name="password"
        value={state.password}
        onChange={updateValue}
        type="password"
      />
      <div className="help-text">Must be atleast 10 characters</div>

      <GuiTextbox
        label="Confirm Password"
        name="password2"
        value={state.password2}
        onChange={updateValue}
        type="password"
      />

      <GuiButton onClick={save} disabled={loggingIn}>
        {loggingIn ? <Spinner /> : "Change"}
      </GuiButton>

      <ErrorMessage error={state.error} />
      <ToastContainer />
    </div>
  );
}
