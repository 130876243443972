import { apiRequest, apiRequestWithChecks } from "./api";

export const authentication = {
    login: function (username, password, installed) {
        return apiRequestWithChecks("PUT", "/api/authentication/login", null,
            {
                Username: username,
                Password: password,
                IsInstalled: installed
            });
    },

    forgot: function (username) {
        return apiRequestWithChecks("PUT", "/api/authentication/forgot", null,
            {
                username: username
            });
    },

    verify: function (username, code) {
        return apiRequestWithChecks("PUT", "/api/authentication/verify", null,
            {
                username: username,
                code: code
            });
    },

    resetPassword: function (resetId, newPassword, isInstalled) {
        return apiRequestWithChecks("PUT", "/api/authentication/reset-password", null,
            {
                resetId: resetId,
                password: newPassword,
                isInstalled: isInstalled
            });
    },

    getVersion: function () {
        return apiRequest("PUT", "/api/authentication/version?nocache=" + new Date().valueOf(), null, null);
    },

    getShortTermToken: function (token) {
        return apiRequestWithChecks("GET", "/api/authentication/short-token", {            
            "Authorization" : "Bearer " + token
        });
    }
}