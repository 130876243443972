import { apiRequestWithChecks } from "./api";

export const admin = {
  getCommunicationReport: function (token, startDate, endDate, commType, sort, pageNum, pageSize, skipTotal) {
    return apiRequestWithChecks("PUT", "/api/commreport/report", {      
      "Authorization" : "Bearer " + token
    },
      {
        start: startDate,
        end: endDate,
        commType: commType,
        sort: sort,
        pageNum: pageNum,
        pageSize: pageSize,
        skipTotal: skipTotal
      });
  },
}