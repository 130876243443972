import '../styles/modal.scss';
import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { AnimationLength } from '../utilities/constants';
import { useUpdateState } from '../utilities/utilities';


export function Modal({ className, children }) {

    return ReactDOM.createPortal(
        <div className={"modal-background " + (className || "")}>
            <div className="modal">
                <div className="modal-content">
                    {children}
                </div>
            </div>
        </div>,
        document.body
    );
}



export function Blackout({ show, onClick }) {
    return (
        <FadeContainer className="blackout" show={show} onClick={onClick} delay={AnimationLength} />
    );
}



export default function FadeContainer({ children, show, delay, className, onClick }) {
    const [local, updateLocal] = useUpdateState({});

    if (show)
        local.lastChildren = children || "";

    useEffect(() => {
        if (local.timer)
            clearTimeout(local.timer);

        if (show)
            local.timer = setTimeout(() => updateLocal({ timer: null, hasFadeIn: true }), 0); // Let the element appear with opacity 0 before fading it in
        else
            local.timer = setTimeout(() => updateLocal({ timer: null, lastChildren: null, hasFadeIn: false }), delay); // The fade animation speed is set in css, this just removes the elements once the animation is complete

        return () => clearTimeout(local.timer); // Cancel the timeout if the control is removed from the dom
    }, [show]);

    let displayChildren = show ? children : local.lastChildren;
    if (displayChildren === null)
        return null;

    return (
        <div className={"fade-container " + (show && local.hasFadeIn ? " fade-in " : "") + (className || "")} onClick={onClick}>
            {displayChildren}
        </div>
    )
}