import api from "../api/api";
import { appState, dispatch } from "../redux/redux-config";
import { Path } from "../utilities/constants";
import navigate from "../utilities/navigation";
import { storage } from "../utilities/storage";

const initialState = {
  tab: Path.People,
  userTypeOptions: [],
};

const people = {
  initPage: function () {
    let state = appState();
    let path = state.router.location.pathname;

    let tab =
      path === Path.PeopleAdmin || path === Path.PeopleManagers
        ? path
        : Path.People;
    if (tab !== state.people.tab) dispatch("PEOPLE_TAB_CHANGE", { tab: tab });

    // Load column visibility
    if (!state.gridColumns) {
      let gridColumns = [
        { name: "Icon", column: "icon-col", show: true },
        { name: "First Name", column: "firstname", show: true },
        { name: "Last Name", column: "lastname", show: true },
        { name: "Person ID", column: "uniqueid", show: false },
        { name: "Address", column: "address", show: false },
        { name: "Phone Code", column: "phonecode", show: false },
        { name: "User Name", column: "username", show: true },
        { name: "User Type", column: "usertype", show: false },
        { name: "Priority", column: "priority", show: false },
      ];
      let saved = storage.getObject("people-columns");
      if (saved)
        gridColumns.forEach((c) => (c.show = saved.includes(c.column)));

      dispatch("PEOPLE_SETTINGS_UPDATE", { gridColumns: gridColumns });
    }
  },

  changeTab: function (tab) {
    navigate(tab);
  },

  showImport: function () {},

  showAddPerson: function () {
    navigate(Path.AddPerson);
  },

  getPeopleGrid: async function (
    search,
    type,
    groupIds,
    criterias,
    sort,
    page,
    pageSize,
    skipTotal
  ) {
    let token = appState().user.token;
    let result = await api.people.getPeopleGrid(
      token,
      search,
      type,
      groupIds,
      criterias,
      sort,
      page,
      pageSize,
      skipTotal
    );
    if (result.success) return result;
  },

  getPersonDetails: async function (personId) {
    let token = appState().user.token;
    return await api.people.getPersonDetails(token, personId);
  },

  addPeopleToGroup: async function (groupId, personIds) {
    let token = appState().user.token;
    let result = await api.people.addPeopleToGroup(token, groupId, personIds);
    if (result.success) return result;
  },

  deletePeople: async function (personIds) {
    let token = appState().user.token;
    let result = await api.people.deletePeople(token, personIds);
    if (result.success) return result;
  },

  setPriority: async function (personIds, high) {
    let token = appState().user.token;
    let result = await api.people.setPriority(token, personIds, high);
    if (result.success) return result;
  },

  savePerson: async function (person) {
    let token = appState().user.token;
    let result = await api.people.save(token, person);
    if (result.success) return result;
  },

  validateUsername: async function (username, showErrorToaster) {
    let token = appState().user.token;
    let result = await api.people.validate(token, username, showErrorToaster);
    return result;
  },
};
export default people;

export function peopleReducer(state, action) {
  if (state === undefined) return initialState;

  switch (action.type) {
    case "PEOPLE_TAB_CHANGE":
    case "PEOPLE_SETTINGS_UPDATE":
      return {
        ...state,
        ...action.payload,
      };

    //case '@@router/LOCATION_CHANGE':
    //    let path = action.payload.location.pathname;
    //    if (path === Path.People || path === Path.PeopleAdmin || path === Path.PeopleManagers) {
    //        return {
    //            ...state,
    //            tab: path
    //        }
    //    }
    //    return state;

    case "LOGOUT":
      return initialState;

    default:
      return state;
  }
}
