import api from "../api/api";
import { appState, dispatch } from "../redux/redux-config";
import { AlertStatus, Path } from "../utilities/constants";
import navigate from "../utilities/navigation";


const track = {
    navigateToTrack: async function (status) {
        if (status === AlertStatus.All)
            navigate(Path.Track);
        else if (status === AlertStatus.Sent)
            navigate(Path.TrackSent);
        else if (status === AlertStatus.Scheduled)
            navigate(Path.TrackScheduled);
        else if (status === AlertStatus.Draft)
            navigate(Path.TrackDrafts);

        dispatch("TRACK_TAB_CHANGE", { status: status });
    },

    getAlerts: async function (status, search, pageNum, pageSize, filters, sort, skipTotal) {
        let token = appState().user.token;
        return await api.alerts.list(token, status, search, pageNum, pageSize, filters, sort, skipTotal);
    }
}
export default track;





export function trackReducer(state, action) {
    if (state === undefined)
        return initialState;

    switch (action.type) {

        case 'TRACK_TAB_CHANGE':
            return {
                ...state,
                filter: action.payload
            }

        case 'LOGOUT':
            return initialState;

        default:
            return state;
    }
}


const initialState = {
    filter: { status: AlertStatus.All, pageNum: 1, pageSize: 10 }
};