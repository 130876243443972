import "../../styles/global-ui/gui-time.scss";
import 'rc-time-picker/assets/index.css';
import React, { useState, useContext } from "react";
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import ValidationContext from './validation';
import Error from "./error";


export function GuiTime({ name, label, value, onChange, disabled, type, className, style, error }) {
    const date = parseDateTime(value);
    let validation = useContext(ValidationContext);
    let [inFocus, setInFocus] = useState(false);
    error = error || (validation && validation.show && validation.getError(name));


    function onTimeChange(mo) {
        const d = date || getToday();

        if (mo)
            d.setHours(mo.hour(), mo.minute(), 0);
        else
            d.setHours(0, 0, 0);

        onChange(name, d);
    }

    return (
        <div className={"gui-time " + (className || "") + (value ? " has-value" : "") + (inFocus ? " in-focus" : "") + (error ? " has-error" : "")}>
            <TimePicker
                value={date ? moment(date) : undefined}
                onChange={onTimeChange}
                format={_use12HourTime ? 'h:mm a' : "HH:mm"}
                minuteStep={5}
                use12Hours={_use12HourTime}
                showSecond={false}
                onFocus={e => setInFocus(true)}
                onBlur={e => setInFocus(false)}
                id="time-picker"
            />
            {label && <label className="control-label">{label}</label>}
            <Error error={error} />
        </div>
    );
}


export function parseDateTime(val) {
    if (!val)
        return null;
    if (!(val instanceof Date))
        val = new Date(val);
    if (isNaN(val.getTime()))
        return null;
    return val;
}

export const _use12HourTime = new Date(2002, 2, 2, 23, 2, 2).toLocaleTimeString().indexOf("11") >= 0;



export function getToday() {
    var now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}