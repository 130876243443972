import '../../styles/file-upload.scss';
import React, { useContext, useRef, useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import ValidationContext from "../global-ui/validation";
import { appState } from '../../redux/redux-config';
import api from '../../api/api';
import { GuiButton } from '../global-ui/gui-button';
import { showToast } from '../toast';

export function FileUploadArea({ name, browseText, dropText, acceptedTypes, disabled, endPoint, maxFileSizeMB, onUploaded, onError, error, browseFilesRef, children }) {
    const validation = useContext(ValidationContext);
    const uploader = useRef({});
    const dropArea = useRef({});
    const progressBar = useRef({});
    const settings = useSelector(store => store.settings);
    const [state, setState] = useState({ uploading: false });
    acceptedTypes = acceptedTypes || ".jpg, .jpeg, .tiff, .tif, .bmp, .png, .doc, .docx, .pdf, .ppt, .pptx, .xls, .xlsx, .zip, .mp3, .3gp, .mp4, .avi, .mov, .wav, .m4a";
    error = error || (validation && validation.show && validation.getError(name));
    state.latestDisabled = disabled;

    function fileChosen() {
        const files = uploader.current.files;
        if (!files || files.length === 0 || disabled)
            return;

        setState({ uploading: true, fileName: files[0].name, percent: 0 });
        let token = appState().user.token;
        api.file.upload(endPoint, token, files[0], fileUpload_update, maxFileSizeMB);
    }

    function fileDropped(e) {
        e.preventDefault();
        e.stopPropagation();

        const files = e.dataTransfer.files;
        if (!files || files.length === 0 || state.latestDisabled)
            return;

        setState({ uploading: true, fileName: files[0].name, percent: 0 });
        let token = appState().user.token;
        api.file.upload(endPoint, token, files[0], fileUpload_update, maxFileSizeMB);
        if (dropArea.current)
            dropArea.current.classList.remove("file-hover");
    }

    function fileUpload_update(progress) {
        if (progress.complete && progress.result.success && onUploaded)
            onUploaded(progress.result.data.url, progress.result.data);

        if (!dropArea.current && !progressBar.current)
            return; // Control has been disposed

        if (!progress.complete)
            setState(s => ({ ...s, percent: progress.percent, cancelCallback: progress.cancel }));
        else if (!progress.result.success) {
            if (onError) {
                onError(progress.result.errorMessage);
                setState({ uploading: false });
            }
            else
                setState({ uploading: false, error: progress.result.errorMessage });
            Array.from(document.querySelectorAll("input[type='file']")).forEach(i => i.value = ""); // So that Adam can try the same file repeatedly and get an error message each time
        }
        else
            setState({ uploading: false });
    }

    function cancelFileUpload() {
        if (state.cancelCallback)
            state.cancelCallback();
        if (dropArea.current || progressBar.current)
            setState({ uploading: false });
    }

    useEffect(() => {
        if (dropArea.current && dropArea.current !== state.lastAttached) {
            state.lastAttached = dropArea.current;

            // Attach events
            const area = dropArea.current;

            function hoverOver(e) {
                area.classList.add('file-hover');
                e.preventDefault();
                e.stopPropagation();
            }

            function hoverLeave(e) {
                area.classList.remove('file-hover');
                e.preventDefault();
                e.stopPropagation();
            }

            area.addEventListener('dragenter', hoverOver, false);
            area.addEventListener('dragover', hoverOver, false);
            area.addEventListener('dragleave', hoverLeave, false);
            area.addEventListener('drop', fileDropped, false);
        }
    });

    if (browseFilesRef)
        browseFilesRef.open = function () { uploader.current.click() }
    
    return (
        <Fragment>
            {(state.error || error) &&
                <p className="has-error"><br />{state.error || error}</p>
            }
            {!state.uploading &&
                <div className="draggable-file-upload" ref={dropArea}>
                    {!children &&
                        <Fragment>
                            <i className="fms-upload-icon"></i>
                            <p>{dropText}</p>
                            <button className="btn btn-link" type="file" onClick={() => { if (!disabled) uploader.current.click() }} data-qa="field-file-upload-file">{browseText}</button>
                        </Fragment>
                    }
                    {children}
                    <input type="file" style={{ display: "none" }} ref={uploader} onChange={fileChosen} accept={acceptedTypes} />
                </div>
            }
            {state.uploading &&
                <div className="file-upload-in-progress">
                    <div>
                        <i className="fal fa-file"></i> {state.fileName}
                    </div>
                    <div className="file-progress-bar">
                        <div className="file-progress" style={{ width: state.percent + "%" }} ref={progressBar}></div>
                    </div>
                    <i className="fal fa-times" onClick={cancelFileUpload}></i>
                </div>
            }
        </Fragment>
    );
}


export function FileUploadButton({ name, onChange, acceptedTypes, maxFileSizeMB, disabled }) {
    let uploader = useRef({});
    acceptedTypes = acceptedTypes || ".jpg, .jpeg, .tiff, .tif, .bmp, .png, .doc, .docx, .pdf, .ppt, .pptx, .xls, .xlsx, .zip, .mp3, .3gp, .mp4, .avi, .mov, .wav, .m4a";
    maxFileSizeMB = maxFileSizeMB || 40;


    function fileChosen() {
        let files = uploader.current.files;
        if (!files || files.length === 0 || disabled)
            return;

        let file = files[0];
        if (file.size > (maxFileSizeMB * 1000 * 1000))
        {
            showToast("error", "File size too large. File must be below " + maxFileSizeMB + "MB.");
            return;
        }

        // Convert the file to a base64 string
        let reader = new FileReader();
        reader.onload = (() => onChange(name, reader.result));
        reader.onerror = (error => showToast("error", "Error reading file: " + error));
        reader.readAsDataURL(file);
    }

    return (
        <>
            <GuiButton className="btn-outline" type="file" disabled={disabled} onClick={() => uploader.current.click()} data-qa="file-upload-button">Choose File</GuiButton>
            <input type="file" style={{ display: "none" }} ref={uploader} onChange={fileChosen} accept={acceptedTypes} />
        </>
    )
}