import "../../styles/global-ui/gui-select.scss";
import React, { useContext, useState } from "react";
import Select from "react-select";
import ValidationContext from "./validation";
import Error from "./error";

export function GuiSelect({
  name,
  options,
  label,
  value,
  onChange,
  isMulti,
  className,
  menuClassName,
  isSearchable,
  enableClear,
  disabled,
  customFilter,
  inModal,
  style,
  error,
  placeholder,
}) {
  //const getStyle = getStyles();
  //const config = useSelector(store => store.config);
  const validation = useContext(ValidationContext);
  error = error || (validation && validation.show && validation.getError(name));
  //const options = !p.customFilter ? (p.options || []).map(o => ({ value: getKey(o) + "", label: getName(o), isDisabled: o.disabled })) : p.options;
  options = options || [];
  let val = value?.value || value;
  const selected = isMulti
    ? options.filter((o) => value && value.indexOf(o.value) >= 0)
    : options.find((o) => o.value == val) || null;
  let [inFocus, setInFocus] = useState(false);
  let hasValue = value || value === 0;

  //const inputStyle = getStyle("inputs");
  //const styles = useMemo(() => {
  //    if ((!config.shouldUseFormStyles && !config.shouldUseSelectStyles) || config.shouldUseDefaultTheme)
  //        return nullSelectStyles;

  //    if (inputStyle) {
  //        return {
  //            control: base => ({ ...base, ...inputStyle }),
  //            container: base => ({ ...base, ...inputStyle }),
  //            singleValue: base => ({ ...base, ...inputStyle.color }),
  //            singleValue: base => ({ ...base, color: inputStyle.color }),
  //            menuList: base => ({ ...base, color: inputStyle.color, fontSize: inputStyle.fontSize, fontFamily: inputStyle.fontFamily, backgroundColor: inputStyle.backgroundColor }),
  //            menuPortal: (!isTouchDevice() && !p.showAsTouch && !p.inModal ? base => ({ ...base, zIndex: 9999 }) : undefined)
  //        }
  //    }
  //    return { menuPortal: (!isTouchDevice() && !p.showAsTouch ? base => ({ ...base, zIndex: 9999 }) : undefined) };
  //}, [config, inputStyle]);

  // The CSS rules excluded from injection include dynamically-generated
  // positioning styles, without which portaling cannot work.
  //const canPortal = !!(config.shouldUseFormStyles || config.shouldUseSelectStyles);

  function addMenuClasses() {
    setTimeout(function () {
      const e = document.querySelector(".ss__menu");
      if (e) {
        //if (styles)
        //    e.classList.add("use-theme");
        if (menuClassName) {
          e.classList.add(menuClassName);
        }
      }
    }, 0);
  }

  return (
    <div
      className={
        "gui-select " +
        (className || "") +
        (hasValue ? " has-value" : "") +
        (inFocus ? " in-focus" : "") +
        (error ? " has-error" : "")
      }
    >
      <Select
        menuPlacement="auto"
        maxMenuHeight={260}
        isSearchable={isSearchable !== false && !isTouchDevice()}
        className={
          "search-select" + (enableClear && selected ? " clear-visible" : "")
        }
        menuPortalTarget={inModal || !isTouchDevice() ? document.body : ""}
        name={name}
        value={selected}
        onFocus={(e) => setInFocus(true)}
        onBlur={(e) => setInFocus(false)}
        //onChange={(item) => onChange(name, item)}
        onChange={(item) => {
          onChange(name, item);
        }}
        options={options}
        placeholder={placeholder}
        isClearable={enableClear}
        isDisabled={disabled || false}
        classNamePrefix="ss"
        filterOption={customFilter}
        onMenuOpen={addMenuClasses}
        isMulti={isMulti}
        closeMenuOnSelect={true}
        styles={style}
      />
      {label && <label className="control-label">{label}</label>}
      <Error error={error} />
    </div>
  );
}

export function isTouchDevice() {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
}
