import React from "react";


export default function LoadingSpinner({ show, className }) {
    if (!show)
        return null;

    return (
        <div className={"loading " + (className || "")}>
            <i className="fal fa-spinner fa-spin"></i>
        </div>
    );
}

export function Spinner({ className }) {
    return <i className={"fa fa-spinner fa-spin " + (className || "")}></i>
}