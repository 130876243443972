import { apiRequestWithChecks } from "./api";


export const groups = {
    list: function (token, search, pageNum, pageSize, skipTotal) {
        return apiRequestWithChecks("GET", "/api/groups/list?search=" + encodeURIComponent(search) + "&pageNum=" + pageNum + "&pageSize=" + pageSize + "&skipTotal=" + skipTotal, {
            "Authorization" : "Bearer " + token
        });
    },

    getGroupGrid: function (token, search, locations, groupTypes, sort, pageNum, pageSize, skipTotal) {
        return apiRequestWithChecks("PUT", "/api/groups/grid", {
            "Authorization" : "Bearer " + token
        },
            {
                Search: search || "",
                Locations: locations?.map(i => parseInt(i)) || null,
                GroupTypes: groupTypes?.map(i => parseInt(i)) || null,
                Sort: sort,
                PageNum: pageNum,
                PageSize: pageSize,
                SkipTotal: skipTotal
            });
    },

    getGroupOptions: function (token) {
        return apiRequestWithChecks("GET", "/api/groups/options", {
            "Authorization" : "Bearer " + token
        });
    },

    getLocations: function (token) {
        return apiRequestWithChecks("GET", "/api/groups/locations", {
            "Authorization" : "Bearer " + token
        });
    },

    getGroupTypes: function (token) {
        return apiRequestWithChecks("GET", "/api/groups/types", {
            "Authorization" : "Bearer " + token
        });
    },

    getGroupDetails: function (token, id) {
        return apiRequestWithChecks("GET", "/api/groups/details?id=" + id, {
            "Authorization" : "Bearer " + token
        });
    },

    getAllGroupMewmbers: function (token, groupId) {
        return apiRequestWithChecks("GET", "/api/groups/members/" + groupId, {
            "Authorization": "Bearer " + token
        });
    },

    handleMakeLeader: function (token, userId) {
        return apiRequestWithChecks("PUT", "/api/groups/makeAdmin?userId=" + userId, {
            "Authorization": "Bearer " + token
        });
    },

    removePeopleFromGroup: function (token, groupId, userId) {
        return apiRequestWithChecks("DELETE", "/api/groups/?groupId=" + groupId + "&userId=" + userId, {
            "Authorization": "Bearer " + token
        });
    },

    deleteUserFromGroup: function (token, groupId, userId) {
        console.log('here is our user id', userId);
        return apiRequestWithChecks("DELETE", `/api/groups/delete?GroupId=${groupId}&userId=${userId}`, {
            "Authorization": "Bearer " + token
        });
    },

    save: function (token, group) {
        return apiRequestWithChecks("PUT", "/api/groups", {
            "Authorization": "Bearer " + token
        }, {
            Group: group
        });
    },

    merge: function (token, data) {
        return apiRequestWithChecks("PUT", "/api/groups/merge", {
            "Authorization": "Bearer " + token
        }, data);
    },

    deleteGroups: function (token, groupIds) {
        return apiRequestWithChecks("DELETE", "/api/groups", {
            "Authorization": "Bearer " + token
        },
            {
                GroupIds: groupIds
            });
    },
}