import '../styles/edit-multiple.scss';
import React from "react";
import Popover from './popover';
import { GuiButton } from './global-ui/gui-button';
import { useUpdateState } from '../utilities/utilities';
import { Icon } from './icon';


export function EditMultiple({ items, onItemClick, count }) {
    let [state, updateState] = useUpdateState({});
    let disabled = (count === 0);

    function onClick(item) {
        updateState("showPopup", false);
        onItemClick(item);
    }

    return (
        <>
            {count > 0 &&
                <span className="edit-multiple-count">{count} selected</span>
            }
            <GuiButton className={"btn-outline edit-multiple-button" + (state.showPopup ? " active" : "") + (disabled ? " disabled" : "")} onClick={() => updateState("showPopup", !state.showPopup)} dataQa="edit-multiple-button">Edit Multiple <Icon icon="chevron-down" weight="400" /></GuiButton>
            <Popover show={state.showPopup} className="no-arrow anchor-right popover-menu edit-multiple-popover" onHide={() => updateState("showPopup", false)}>
                {items.map((item, i) =>
                    <div className="menu-item" onClick={e => onClick(item)} key={i}>{item.name}</div>)
                }
            </Popover>
        </>
    );
}

