import React, { useContext } from "react";
import ValidationContext from './validation';
import Error from './error';

// Lazy here saves about 1.4MB of transfer (7.4MB of resources) on the initial load
const HtmlEditorCore = React.lazy(() => import('./gui-html-editor-core.jsx'));
const LazyHtmlEditorCore = (props) => <React.Suspense fallback={<div className="loading-page"><i className="fa fa-spinner fa-spin"></i></div>}><HtmlEditorCore {...props} /></React.Suspense>;



export default function GuiHtmlEditor({ name, value, label, className, onChange, onBlur, fakeRef, error, onOpenTemplatePicker, onAttachmentAdded }) {
    //const theme = useContext(ThemeContext);
    //const getStyle = (theme && theme.getStyle ? theme.getStyle : () => { });
    const validation = useContext(ValidationContext);
    error = error || (validation && validation.show && validation.getDisplayError(name));

    return (
        <>
            <div className={"html-editor " + (className || "") + (error ? " has-error" : "")}>
                <label className="control-label">{label}</label>
                <LazyHtmlEditorCore name={name} value={value} onChange={onChange} onOpenTemplatePicker={onOpenTemplatePicker} onAttachmentAdded={onAttachmentAdded} onBlur={onBlur} fakeRef={fakeRef} />
            </div>
            <Error error={error} />
        </>
    );
}