import React from "react";
import "../styles/circularProgress.scss";

const CircularProgress = () => {
  return (
    <div className="circular-progress">
      <div className="loader"></div>
    </div>
  );
};

export default CircularProgress;
