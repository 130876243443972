import "../../styles/global-ui/gui-radio.scss";
import React from "react";


export function GuiRadio({ name, id, label, value, onChange, disabled, className }) {
    return (
        <label className={"gui-radio " + (className || "") + (disabled ? " disabled" : "")}>
            <input type="radio" name={name} value={id} checked={id === value} onChange={e => onChange(name, e.target.value)} disabled={disabled} />
            <i className="dot"></i>
            {label && <span>{label}</span>}
        </label>
    );
}


//export function RadioList() {
//    //let validation = useContext(ValidationContext);
//    //error = error || (validation && validation.show && validation.getError(name));
//}