import React, { useEffect, useState } from "react";
import "../styles/edit-preference-popup.scss";
import { useSelector } from "react-redux";
import { GuiButton } from "../components/global-ui/gui-button";
import { useUpdateState } from "../utilities/utilities";
import { GuiCheckbox } from "../components/global-ui/gui-checkbox";
import { GuiTextbox } from "../components/global-ui/gui-textbox";
import VoiceDropdown from "../components/voice-dropdown";
import { SettingsApi } from "../actions/settings-action";
import AudioPlayer from "../components/audio-player";
import CircularProgress from "../actions/CircularProgress";
import user from "../actions/user-actions";
import { showToast } from "../components/toast";

const EditPreferencePopup = ({
  isEditPreference,
  onClose,
  onSucessfullAdd,
  preferenceValue,
}) => {
  let [state, updateState] = useUpdateState(() => {
    return {
      isUpdateDisabled: false,
      enableEmail: false,
      email: "",
      typeOptions: [],
      optionType: null,
      selectedVoiceId: 28,
      loading: false,
      fileUrl: null,
      isAudioLoading: true,
    };
  });
  let isMobile = useSelector((store) => store.page.isMobile);
  // console.log("$ preferenceValue : ", preferenceValue);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  useEffect(() => {
    voiceOptions();
  }, []);

  const [fieldErrors, setFieldErrors] = useState({
    email: false,
  });
  async function voiceOptions() {
    let response = await SettingsApi.getVoiceOptions().then((result) => {
      const typeOptions = [
        { label: "English - Kate", value: result.voices[1].id },
        { label: "English - Julie", value: result.voices[0].id },
        { label: "English - Paul", value: result.voices[2].id },
        { label: "English (UK) - Bridget", value: result.voices[3].id },
        { label: "Spanish - Violeta", value: result.voices[4].id },
      ];
      updateState({
        typeOptions: typeOptions,
      });
      updateState({ optionType: result.voices[1].id });
      if (preferenceValue && Object.keys(preferenceValue).length > 0) {
        const selectedVoiceId = typeOptions.find(
          (items) => items.label === preferenceValue.selectedVoiceId
        )?.value;

        updateState({ loading: true });
        updateState({
          enableEmail: preferenceValue.enableEmail,
          email: preferenceValue.email,
          selectedVoiceId: selectedVoiceId,
          optionType: selectedVoiceId,
        });
        sampleFile(selectedVoiceId);
      } else {
        sampleFile(state.selectedVoiceId);
      }
    });
  }
  function validateEmail() {
    if (state.email !== "") {
      if (emailRegex.test(state.email)) {
        return true;
      } else {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          email: true,
        }));

        return false;
      }
    }
    return true;
  }
  const onUpdate = async () => {
    if (state.enableEmail) {
      if (!validateEmail()) {
        return;
      }
    }

    updateState({ isUpdateDisabled: true });
    const payload = {
      VoiceId: state.selectedVoiceId,
      SmsEnableNotify: state.enableEmail,
      SmsNotifyEmail: state.email,
    };
    let result = await user.updatePreference(payload);
    if (result.success) {
      updateState({ isUpdateDisabled: false });
      showToast("success", "Preferences updated successfull");
      onClose();
      onSucessfullAdd();
    }
  };
  const onFilterChange = (name, value) => {
    updateState({ isAudioLoading: true });
    updateState({ [name]: value });
    updateState({ selectedVoiceId: value });
    sampleFile(value);
  };
  const handleChange = (name, value) => {
    if (name === "enableEmail") {
      !value && updateState({ email: "" });
    }

    updateState(name, value);
  };
  async function sampleFile(selectedVoiceId) {
    let result = await SettingsApi.sampleFile({
      id: selectedVoiceId,
    });
    if (result) {
      updateState({ fileUrl: result.fileUrl });
      updateState({ isAudioLoading: false });
    } else {
      updateState({ fileUrl: null });
    }
  }

  return (
    <div
      className={`edit-preference Modal ${isEditPreference ? "Show" : "hide"}`}
    >
      <>
        <div className="modal-header">
          {isMobile && (
            <span onClick={onClose} className="mr-2">
              <i className="fa fa-chevron-left close-primary-icon cursor-pointer"></i>
            </span>
          )}
          <h4>Edit Preferences</h4>
          {!isMobile && (
            <span onClick={onClose}>
              <i className="fa fa-close close-primary-icon cursor-pointer"></i>
            </span>
          )}
        </div>
        <div className="modal-body">
          <span className="text-base-primary">Text Notification Email</span>
          <div className="email-checkbox">
            <div className="checkbox-row">
              <GuiCheckbox
                label="Notify me when I get a text response"
                name="enableEmail"
                value={state.enableEmail}
                onChange={handleChange}
              />
            </div>
          </div>
          {state.enableEmail && (
            <>
              <div className="email-field">
                <div className="email-field-row">
                  <GuiTextbox
                    label="Text Notification Email"
                    name="email"
                    className={`email ${
                      fieldErrors.email && "has-email-error"
                    }`}
                    value={state.email}
                    onChange={handleChange}
                    error={
                      fieldErrors.email && (
                        <span>
                          <i className="fas fa-exclamation-circle"></i> Invalid
                          Email Address
                        </span>
                      )
                    }
                  />
                </div>
                <span className="helper-text">
                  Leave this blank to use your primary email address
                </span>
              </div>
            </>
          )}
          <div className="voice-preference">
            <h4>Voice Preference</h4>
            <div className="voice-preference-dropdown">
              {state.loading && (
                <>
                  <VoiceDropdown
                    onChange={onFilterChange}
                    optionType={state.optionType}
                    typeOptions={state.typeOptions}
                  />
                  <span>
                    The voice your text-to-speech messages will sound like
                  </span>
                </>
              )}
            </div>
            <div className="voice-sample">
              <span>Voice Sample</span>
              {state.isAudioLoading ? (
                <CircularProgress />
              ) : (
                <AudioPlayer src={state.fileUrl} autoPlay={false} />
              )}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="modal-footer-hr-divider"></div>
          <div className="modal-footer-btns">
            <GuiButton className="cancel-btn" onClick={onClose}>
              Close
            </GuiButton>

            <GuiButton
              className="add-btn"
              onClick={onUpdate}
              disabled={state.isUpdateDisabled}
            >
              Update
            </GuiButton>
          </div>
        </div>
      </>
    </div>
  );
};

export default EditPreferencePopup;
