import api from "../api/api";
import { appState, dispatch } from "../redux/redux-config";
import { Path } from "../utilities/constants";
import navigate from "../utilities/navigation";
import { storage } from "../utilities/storage";


const initialState = {
    //tab: Path.People,
    //groupOptions: [],
    //userTypeOptions: [],
    //filter: { status: AlertStatus.All, pageNum: 1, pageSize: 10 }
};


const report = {
  getRecentActivity: async function (
    start,
    end,
    activityType,
    sort,
    page,
    pageSize,
    skipTotal
  ) {
    let token = appState().user.token;
    let result = await api.report.getRecentActivity(
      token,
      start,
      end,
      activityType,
      sort,
      page,
      pageSize,
      skipTotal
    );
    if (result.success) return result;
  },

  getOptOutReport: async function (
    search,
    sort,
    pageNum,
    pageSize,
    skipTotal
  ) {
    let token = appState().user.token;
    let result = await api.report.getOptOutReport(
      token,
      search,
      sort,
      pageNum,
      pageSize,
      skipTotal
    );
    if (result) return result;
  },
};
export default report;





export function reportReducer(state, action) {
    if (state === undefined)
        return initialState;

    switch (action.type) {

        //case 'PEOPLE_SETTINGS_UPDATE':
        //    return {
        //        ...state,
        //        ...action.payload
        //    }

        default:
            return state;
    }
}