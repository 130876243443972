import { useSelector } from "react-redux";
import actions from "../actions/actions";
import { GuiTextbox } from "../components/global-ui/gui-textbox";
import "../styles/edit-profile.scss";
import { useUpdateState } from "../utilities/utilities";
import { GuiButton } from "../components/global-ui/gui-button";
import user from "../actions/user-actions";
import { useEffect } from "react";
import CircularProgress from "../actions/CircularProgress";
import { showToast } from "../components/toast";
import { UserRoleName } from "../utilities/constants";
import { GuiSelect } from "../components/global-ui/gui-select";

export default function EditProfile({
  displayModal,
  onCloseClick,
  onSucessfullAdd,
}) {
  let userTypeOptions =
    useSelector((store) => actions.user.loadUserTypes()) || [];
  let [state, updateState] = useUpdateState({
    firstName: "",
    lastName: "",
    userType: "",
    loading: true,
    primaryEmail: "",
    profileImage: "",
    isUpdateDisabled: false,
    userTypeName: "",
  });
  useEffect(() => {
    getUserDetails();
  }, []);

  async function getUserDetails() {
    updateState({ loading: true });
    await user.getSpecificUserDetails().then((result) => {
      if (result.success) {
        const userTypeName = Object.keys(UserRoleName).find(
          (role) => UserRoleName[role] === result.userType
        );

        updateState({
          firstName: result.firstName,
          lastName: result.lastName,
          userType: result.userType,
          primaryEmail: result.emailAddress,
          userTypeName: userTypeName,
          loading: false,
        });
      }
    });
  }
  const handleChange = (name, value) => {
    updateState(name, value);
  };
  const onUpdateClick = () => {
    updateState({ loading: true, isUpdateDisabled: true });
    const payload = {
      profileImage: state.profileImage,
      firstName: state.firstName,
      lastName: state.lastName,
      emailAddress: state.primaryEmail,
    };

    user.updateUserProfile(payload).then((response) => {
      if (response.success) {
        updateState({ loading: false });
        onCloseClick();
        onSucessfullAdd();
        showToast("success", "Contact Updated");
      }
    });
  };
  return (
    <>
      <div className={`edit-profile Modal ${displayModal ? "Show" : "hide"}`}>
        {state.loading ? (
          <CircularProgress />
        ) : (
          <>
            <div className="edit-profile-header">
              <h4>Edit Profile</h4>
              <span onClick={onCloseClick}>
                <i className="fa fa-close close-primary-icon cursor-pointer"></i>
              </span>
            </div>
            <div className="edit-profile-body">
              <div>
                <span className="edit-profile-body-title">Profile Photo</span>
                <div className="edit-profile-body-image">
                  <div className="background-curve"></div>
                  <div className="profile-image-container">
                    {/* <img
                src={image} // Replace with actual image path
                alt="Profile"
                className="profile-image"
              /> */}
                    <div className="initials">
                      {(state.firstName + " " + state.lastName)
                        .split(" ")
                        .map((n) => n[0])
                        .join("")}
                    </div>
                  </div>
                </div>
                <div className="edit-profile-body-textbox">
                  <GuiTextbox
                    label="First Name"
                    name="firstName"
                    value={state.firstName}
                    onChange={(name, value) => handleChange(name, value)}
                  />
                </div>
                <div className="edit-profile-body-textbox">
                  <GuiTextbox
                    label="Last Name"
                    name="lastName"
                    value={state.lastName}
                    onChange={(name, value) => handleChange(name, value)}
                  />
                </div>
                <div className="edit-profile-body-select">
                  {state.userType === 4 ? (
                    <GuiSelect
                      label="User Type"
                      name="userType"
                      value={state.userType}
                      options={userTypeOptions}
                      onChange={(name, option) =>
                        handleChange(name, option.value)
                      }
                    />
                  ) : (
                    <div>
                      <span className="user-type-name">User Type : </span>
                      <GuiButton
                        className={`role-badge${
                          state.userType === 1 ? "-student" : "-faculty"
                        }`}
                      >
                        {state.userTypeName}
                      </GuiButton>
                    </div>
                  )}
                </div>
                <h4>Primary Contact</h4>
                <div className="edit-profile-body-textbox">
                  <GuiTextbox
                    label="Primary Email"
                    name="primaryEmail"
                    value={state.primaryEmail}
                    onChange={(name, value) => handleChange(name, value)}
                  />
                </div>
              </div>
            </div>
            <div className="hr-divider"></div>

            <div className="edit-profile-btns">
              <GuiButton className="cancel-btn" onClick={onCloseClick}>
                Cancel
              </GuiButton>
              <GuiButton
                className="update-btn"
                disabled={state.isUpdateDisabled}
                onClick={onUpdateClick}
              >
                Update
              </GuiButton>
            </div>
          </>
        )}
      </div>
    </>
  );
}
