import "../styles/people.scss";
import React, { useMemo } from "react";
import PageLayout from "../components/page-layout";
import actions from "../actions/actions";
import { Path } from "../utilities/constants";
import { GuiButton } from "../components/global-ui/gui-button";
import { useSelector } from "react-redux";
import Card from "../components/card";
import { SearchBox } from "../components/search-box";
import {
  downloadFile,
  pluralize,
  useUpdateState,
} from "../utilities/utilities";
import Grid, { ColumnHeader, GridCell } from "../components/grid";
import {
  CheckBoxHeaderButton,
  GuiCheckbox,
} from "../components/global-ui/gui-checkbox";
import Popover from "../components/popover";
import { GuiSelect } from "../components/global-ui/gui-select";
import people from "../actions/people-actions";
import { useOnFirstLoad } from "../utilities/hooks";
import { Modal } from "../components/modal";
import navigate from "../utilities/navigation";
import { GuiTooltip } from "../components/global-ui/gui-tooltip";
import { Icon, SeparatorDot } from "../components/icon";
import { FilterDropdown } from "../components/filter-dropdown";
import { EditMultiple } from "../components/edit-multiple";
import { ColumnPicker } from "../components/column-picker";
import { storage } from "../utilities/storage";
import { dispatch } from "../redux/redux-config";
import { FilterTagBar } from "../components/filter-tag-bar";
import { Tag } from "../components/tag";
import { EditPersonModal } from "../components/edit-person-modal";

export default function PeoplePage() {
  useOnFirstLoad(people.initPage);
  let { gridColumns } = useSelector((store) => store.people);
  let groupOptions =
    useSelector((store) => actions.groups.loadGroupOptions()) || [];
  let userTypeOptions =
    useSelector((store) => actions.user.loadUserTypes()) || [];
  let isMobile = useSelector((store) => store.page.isMobile);
  let [checkboxState, updateCheckboxState, setCheckboxState] = useUpdateState(
    {}
  );

  let [state, updateState] = useUpdateState(() => {
    return {
      criteriaOptions: [
        { value: "no-login", label: "Never Logged In" },
        { value: "no-email", label: "No Email" },
        { value: "no-cell", label: "No Cell Number" },
        { value: "no-phone", label: "No Phone Number" },
        { value: "no-phone-or-email", label: "No Phone Number or Email" },
        { value: "one-phone", label: "Only One Phone Number" },
        { value: "has-phone", label: "Has a Phone Number" },
        { value: "no-import-delete", label: "Do Not Delete On Import" },
        { value: "alert-permission", label: "Has Alert Permissions" },
        { value: "opted-out-sms", label: "Opted Out of Text Messages" },
      ],
      editMultipleItems: [
        { name: "Add Users to Group" },
        { name: "Delete Users from System" },
        { name: "Send Alert to Users" },
        { name: "Make High Priority" },
        { name: "Remove High Priority" },
        { name: "Export" },
      ],
      gridReset: 0,
      search: "",
      filters: [],
      sort: {},
      perms: {},
    };
  });

  let filterOptions = [
    { name: "User Type", options: userTypeOptions },
    { name: "Group", options: groupOptions },
    { name: "Criteria", options: state.criteriaOptions },
  ];

  let selectedRows = Object.keys(checkboxState).filter((k) => checkboxState[k]);
  let { sort, perms } = state;

  gridColumns = gridColumns || [];
  let visibility = useMemo(() => {
    let vis = {};
    gridColumns.forEach((c) => (vis[c.column] = c.show));
    return vis;
  }, [gridColumns]);

  async function loadRows(page, pageSize, skipTotal) {
    let search = state.search;
    let userTypes = state.filters
      .filter((f) => f.name === "User Type")
      .map((f) => f.value);
    let userType =
      userTypes.length > 1 ? -1 : userTypes.length === 1 ? userTypes[0] : null;
    let groups = state.filters
      .filter((f) => f.name === "Group")
      .map((f) => f.value);
    let criterias = state.filters
      .filter((f) => f.name === "Criteria")
      .map((f) => f.value);
    let sort = Object.keys(state.sort)
      .map((col) => ({ column: col, asc: state.sort[col] }))
      .filter((s) => s.asc !== undefined)
      .map((s) => s.column + " " + (s.asc ? "asc" : "desc"))
      .join(",");

    updateState({ loading: true });

    let result = await actions.people.getPeopleGrid(
      search,
      userType,
      groups,
      criterias,
      sort,
      page,
      pageSize,
      skipTotal
    );

    let changes = {
      loading: false,
      latestPage: result.list,
    };
    if (result.permissions) {
      changes.perms = result.permissions;
      if (!result.permissions.canExport)
        changes.editMultipleItems = state.editMultipleItems.filter(
          (i) => i.name !== "Export"
        );
      if (result.permissions.deleteLevel <= 0)
        changes.editMultipleItems = state.editMultipleItems.filter(
          (i) => i.name !== "Delete Users from System"
        );
    }
    updateState(changes);
    return result;
  }

  function setSearch(search) {
    updateState({ search: search, gridReset: state.gridReset + 1 });
  }

  function onFilterChange(action, values) {
    if (action === "clear")
      updateState({ filters: [], gridReset: state.gridReset + 1 });
    else {
      let newFilters = state.filters.concat(values);
      updateState({ filters: newFilters, gridReset: state.gridReset + 1 });
    }
  }

  function removeFilter(filter) {
    updateState({
      filters: state.filters.filter((f) => f !== filter),
      gridReset: state.gridReset + 1,
    });
  }

  function onSort(column) {
    let currentDirection = state.sort[column];
    let nextDirection =
      currentDirection === true
        ? false
        : currentDirection === false
          ? undefined
          : true;
    updateState({
      sort: { ...state.sort, [column]: nextDirection },
      gridReset: state.gridReset + 1,
    });
  }

  function onPageChanged(list) {
    setCheckboxState({});
    updateState({ currentPage: list });
  }

  async function onEditMultipleClick(item) {
    if (item.name === "Add Users to Group")
      updateState({ showAddToGroupModal: true, individual: null });
    else if (item.name === "Delete Users from System")
      updateState({ showDeleteUsersModal: true, individual: null });
    else if (item.name === "Send Alert to Users") goToSendAlert();
    else if (item.name === "Make High Priority")
      updateState({ showMakeHighPriority: true, individual: null });
    else if (item.name === "Remove High Priority")
      updateState({ showRemoveHighPriority: true, individual: null });
    else if (item.name === "Export") {
      let token = await actions.user.getShortTermToken();
      if (token) {
        downloadFile(
          "/api/people/export?personIds=" +
          Object.keys(checkboxState)
            .filter((k) => checkboxState[k])
            .join(",") +
          "&token=" +
          encodeURIComponent(token)
        );
      }
    }
  }

  function onColumnChange(item, show) {
    let cols = [...gridColumns];
    let i = cols.findIndex((c) => c.name === item.name);
    cols[i] = { ...cols[i], show: show };

    let list = cols.filter((c) => c.show).map((c) => c.column);
    storage.setObject("people-columns", list);

    dispatch("PEOPLE_SETTINGS_UPDATE", { gridColumns: cols });
  }

  function toggleAllCheckboxes() {
    let any = Object.keys(checkboxState).find((k) => checkboxState[k]);
    if (any || !state.currentPage) setCheckboxState({});
    else
      setCheckboxState(
        state.currentPage.reduce((newState, row) => {
          newState[row.id] = true;
          return newState;
        }, {})
      );
  }

  function getUsersText() {
    return state.individual
      ? state.individual.firstName + " " + state.individual.lastName
      : "these users";
  }

  async function addUsersToGroup() {
    let userIds = state.individual
      ? [state.individual.id]
      : Object.keys(checkboxState).filter((k) => checkboxState[k]);
    updateState({ saving: true });
    await actions.people.addPeopleToGroup(state.addToGroupId, userIds);
    updateState({
      saving: false,
      showAddToGroupModal: false,
      addToGroupId: null,
      individual: null,
    });
  }

  async function deleteUsers() {
    let userIds = state.individual
      ? [state.individual.id]
      : Object.keys(checkboxState).filter((k) => checkboxState[k]);
    updateState({ saving: true });
    await actions.people.deletePeople(userIds);
    updateState({
      saving: false,
      showDeleteUsersModal: false,
      individual: null,
      gridReset: state.gridReset + 1,
    });
  }

  async function setUserPriority(setHigh) {
    let userIds = state.individual
      ? [state.individual.id]
      : Object.keys(checkboxState).filter((k) => checkboxState[k]);
    updateState({ saving: true });
    await actions.people.setPriority(userIds, setHigh);
    updateState({
      saving: false,
      showMakeHighPriority: false,
      showRemoveHighPriority: false,
      individual: null,
      gridReset: state.gridReset + 1,
    });
  }

  function showDashboard(id) {
    navigate(Path.UserDashboard + "/" + id);
  }

  async function goToSendAlert(userId) {
    let userIds = selectedRows;
    let userIdsAsNumbers = userIds.map(id => parseInt(id, 10));
    let users = state.currentPage.filter(p => userIdsAsNumbers.includes(p.id));
    const firstNames = users.map(user => user.firstName.split(',')[0].trim());
    const groupName = `${firstNames.join(', ')} - ${new Date().toISOString()}`;
    //create group based on users :
    let groupData = {
      id: 0,
      name: groupName,
      description: groupName,
      groupCode: '',
      groupType: 5,
      allowOptIn: true,
      locationId: 0,
      alwaysAttach: 0,
      callerId: '',
      emergencyCallerId: '',
      groupEmail: '',
      groupEmailName: '',
      reportingEmail: '',
      reportingEmailName: '',
      logoUrl: '',
      users: users,
      is_temp: 1
    };
    let result = await actions.groups.save(groupData);
    if (result && result.success) {
      groupData.id = result.id;
      await actions.people.addPeopleToGroup(result.id, userIdsAsNumbers);
      let group = {
        id: result.id,
        name: groupData.name,
        location: groupData.locationId,
        description: groupData.description,
        groupType: groupData.groupType,
        groupTypeName: '',
        groupCode: groupData.groupCode,
        createdUserId: 0,
        userCount: groupData.users.count,
        alertCount: 0
      };
      let groupArray = [group];
      actions.alerts.showCreateAlert(false, groupArray, null);
    }

  }

  async function exportAll() {
    let token = await actions.user.getShortTermToken();
    if (token) {
      let search = state.search;
      let userTypes = state.filters
        .filter((f) => f.name === "User Type")
        .map((f) => f.value);
      let userType =
        userTypes.length > 1
          ? -1
          : userTypes.length === 1
            ? userTypes[0]
            : null;
      let groups = state.filters
        .filter((f) => f.name === "Group")
        .map((f) => f.value);
      let criterias = state.filters
        .filter((f) => f.name === "Criteria")
        .map((f) => f.value);
      let sort = Object.keys(state.sort)
        .map((col) => ({ column: col, asc: state.sort[col] }))
        .filter((s) => s.asc !== undefined)
        .map((s) => s.column + " " + (s.asc ? "asc" : "desc"))
        .join(",");

      downloadFile(
        "/api/people/export?search=" +
        encodeURIComponent(search) +
        "&userType=" +
        (userType || "") +
        "&groups=" +
        groups.join(",") +
        "&criterias=" +
        criterias.join(",") +
        "&sort=" +
        (sort || "") +
        "&token=" +
        encodeURIComponent(token)
      );
    }
  }

  async function exportAll() {
    let token = await actions.user.getShortTermToken();
    if (token) {
      let search = state.search;
      let userTypes = state.filters
        .filter((f) => f.name === "User Type")
        .map((f) => f.value);
      let userType =
        userTypes.length > 1
          ? -1
          : userTypes.length === 1
            ? userTypes[0]
            : null;
      let groups = state.filters
        .filter((f) => f.name === "Group")
        .map((f) => f.value);
      let criterias = state.filters
        .filter((f) => f.name === "Criteria")
        .map((f) => f.value);
      let sort = Object.keys(state.sort)
        .map((col) => ({ column: col, asc: state.sort[col] }))
        .filter((s) => s.asc !== undefined)
        .map((s) => s.column + " " + (s.asc ? "asc" : "desc"))
        .join(",");

      downloadFile(
        "/api/people/export?search=" +
        encodeURIComponent(search) +
        "&userType=" +
        (userType || "") +
        "&groups=" +
        groups.join(",") +
        "&criterias=" +
        criterias.join(",") +
        "&sort=" +
        (sort || "") +
        "&token=" +
        encodeURIComponent(token)
      );
    }
  }

  return (
    <PageLayout
      title="People"
      className="people"
      buttonBar={
        !isMobile ? (
          <>
            <GuiButton
              dataQa="import-button"
              className="btn-outline"
              onClick={() => actions.people.showImport()}
            >
              Import
            </GuiButton>
            {perms.canExport && (
              <GuiButton
                dataQa="export-button"
                className="btn-outline"
                onClick={exportAll}
              >
                Export
              </GuiButton>
            )}
            <GuiButton
              dataQa="add-person-button"
              className=""
              onClick={actions.people.showAddPerson}
            >
              <Icon icon="plus" weight="400" />
              Add
            </GuiButton>
          </>
        ) : (
          <>
            <GuiButton
              dataQa="add-person-button"
              className=""
              onClick={actions.people.showAddPerson}
            >
              <Icon icon="plus" weight="400" />
              Add
            </GuiButton>
          </>
        )
      }
      onMobileSearch={setSearch}
      searching={state.loading}
    >
      <Card>
        {/* Desktop Search bar */}
        {!isMobile && (
          <div className="page-layout-content-header">
            <SearchBox
              onSearchChange={setSearch}
              searching={state.loading}
              minLength={3}
              dataQa="search"
            />
            <FilterDropdown
              filters={filterOptions}
              onFilterChange={onFilterChange}
            />

            <div className="tools">
              <EditMultiple
                items={state.editMultipleItems}
                onItemClick={onEditMultipleClick}
                count={selectedRows.length}
              />
              <ColumnPicker items={gridColumns} onItemChange={onColumnChange} />
            </div>

            {/* Filter Tags */}
            <FilterTagBar filters={state.filters} onRemove={removeFilter} />
          </div>
        )}

        {/* Mobile filter bar */}
        {isMobile && (
          <div className="mobile-filter-bar-with-btns">
            <GuiButton
              dataQa="import-button"
              className="btn-outline"
              onClick={() => actions.people.showImport()}
            >
              Import
            </GuiButton>
            {perms.canExport && (
              <GuiButton
                dataQa="export-button"
                className="btn-outline"
                onClick={exportAll}
              >
                Export
              </GuiButton>
            )}

            <FilterDropdown
              filters={filterOptions}
              onFilterChange={onFilterChange}
            />
          </div>
        )}

        {/* Grid */}
        <Grid
          header={
            <div className="header-row row">
              <ColumnHeader className="check">
                <CheckBoxHeaderButton
                  checked={Object.keys(checkboxState).find(
                    (k) => checkboxState[k]
                  )}
                  onClick={toggleAllCheckboxes}
                />
              </ColumnHeader>
              <ColumnHeader
                className="icon-col"
                visibility={visibility}
              ></ColumnHeader>
              <ColumnHeader
                className="firstname"
                sort={sort}
                onSort={onSort}
                visibility={visibility}
              >
                First Name
              </ColumnHeader>
              <ColumnHeader
                className="lastname"
                sort={sort}
                onSort={onSort}
                visibility={visibility}
              >
                Last Name
              </ColumnHeader>
              <ColumnHeader
                className="uniqueid"
                sort={sort}
                onSort={onSort}
                visibility={visibility}
              >
                ID
              </ColumnHeader>
              <ColumnHeader
                className="address"
                sort={sort}
                onSort={onSort}
                visibility={visibility}
              >
                Address
              </ColumnHeader>
              <ColumnHeader
                className="phonecode"
                sort={sort}
                onSort={onSort}
                visibility={visibility}
              >
                Phone Code
              </ColumnHeader>
              <ColumnHeader
                className="username"
                sort={sort}
                onSort={onSort}
                visibility={visibility}
              >
                User Name
              </ColumnHeader>
              <ColumnHeader
                className="usertype"
                sort={sort}
                onSort={onSort}
                visibility={visibility}
              >
                User Type
              </ColumnHeader>
              <ColumnHeader
                className="priority"
                sort={sort}
                onSort={onSort}
                visibility={visibility}
              >
                Priority
              </ColumnHeader>
              <ColumnHeader className="send"></ColumnHeader>
              <ColumnHeader className="actions"></ColumnHeader>
            </div>
          }
          itemTemplate={(p) => (
            <div className="row" key={p.id}>
              <GridCell className="check">
                <GuiCheckbox
                  name={"" + p.id}
                  value={checkboxState[p.id]}
                  onChange={updateCheckboxState}
                />
              </GridCell>
              <GridCell className="icon-col" visibility={visibility}>
                <span className="icon">
                  {(p.firstName || "").substr(0, 1).toUpperCase() +
                    (p.lastName || "").substr(0, 1).toUpperCase()}
                </span>
              </GridCell>
              <GridCell className="firstname" visibility={visibility}>
                {p.firstName || ""}
              </GridCell>
              <GridCell className="lastname" visibility={visibility}>
                {p.lastName || ""}
              </GridCell>
              <GridCell className="uniqueid" visibility={visibility}>
                {p.uniqueid || ""}
              </GridCell>
              <GridCell className="address" visibility={visibility}>
                {p.address || ""} {p.city || ""} {p.state || ""} {p.zip || ""}
              </GridCell>
              <GridCell className="phonecode" visibility={visibility}>
                {p.phonecode || ""}
              </GridCell>
              <GridCell className="username" visibility={visibility}>
                {p.userName || ""}
              </GridCell>
              <GridCell className="usertype" visibility={visibility}>
                <UserTypeTag user={p} types={userTypeOptions} />
              </GridCell>
              <GridCell className="priority" visibility={visibility}>
                {p.priority && <Tag className="high-priority" label="High" />}
              </GridCell>
              <GridCell className="send">
                <GuiTooltip tooltip="Send Alert">
                  <i
                    className="far fa-paper-plane icon-button"
                    onClick={(e) => goToSendAlert(p.id)}
                  />
                </GuiTooltip>
              </GridCell>
              <GridCell className="actions">
                <Icon
                  icon="ellipsis-h"
                  weight="400"
                  onClick={(e) => updateState("showActionsMenu", p.id)}
                />
                <Popover
                  show={state.showActionsMenu === p.id}
                  className="no-arrow anchor-right popover-menu actions-menu"
                  onHide={() => updateState("showActionsMenu", null)}
                >
                  <div
                    className="menu-item"
                    onClick={(e) => showDashboard(p.id)}
                  >
                    View Dashboard
                  </div>
                  <div
                    className="menu-item"
                    onClick={(e) =>
                      updateState({
                        showActionsMenu: null,
                        showAddToGroupModal: true,
                        individual: p,
                      })
                    }
                  >
                    Add User to Group
                  </div>
                  <div
                    className="menu-item"
                    onClick={(e) => goToSendAlert(p.id)}
                  >
                    Send Alert
                  </div>
                  {p.priority ? (
                    <div
                      className="menu-item"
                      onClick={(e) =>
                        updateState({
                          showActionsMenu: null,
                          showRemoveHighPriority: true,
                          individual: p,
                        })
                      }
                    >
                      Remove High Priority
                    </div>
                  ) : (
                    <div
                      className="menu-item"
                      onClick={(e) =>
                        updateState({
                          showActionsMenu: null,
                          showMakeHighPriority: true,
                          individual: p,
                        })
                      }
                    >
                      Make High Priority
                    </div>
                  )}
                  <div
                    className="menu-item"
                    onClick={(e) =>
                      updateState({
                        showActionsMenu: null,
                        showEditUserModal: true,
                        individual: p,
                      })
                    }
                  >
                    Edit
                  </div>
                  {p.userTypeId <= perms.deleteLevel && (
                    <div
                      className="menu-item"
                      onClick={(e) =>
                        updateState({
                          showActionsMenu: null,
                          showDeleteUsersModal: true,
                          individual: p,
                        })
                      }
                    >
                      Delete
                    </div>
                  )}
                </Popover>
              </GridCell>
            </div>
          )}
          mobileItemTemplate={(p) => (
            <div
              className="item"
              onClick={() => showDashboard(p.id)}
              key={p.id}
            >
              <span className="icon-col">
                <span className="icon">
                  {(p.firstName || "").substr(0, 1).toUpperCase() +
                    (p.lastName || "").substr(0, 1).toUpperCase()}
                </span>
              </span>
              <div className="info">
                <h6>
                  <span>
                    {p.firstName || ""} {p.lastName || ""}
                  </span>
                  <UserTypeTag user={p} types={userTypeOptions} />
                </h6>
                <p>
                  {p.userName}
                  <SeparatorDot />
                  {p.address || ""} {p.city || ""} {p.state || ""} {p.zip || ""}
                </p>
              </div>
              <Icon icon="chevron-right" weight="400" />
            </div>
          )}
          mobileItemHeight={65}
          reset={state.gridReset}
          pageSize={20}
          onItemsNeeded={loadRows}
          onPageChanged={onPageChanged}
          mobileFooter={(count) =>
            !state.loading
              ? count + " " + pluralize("Person", state.totalCount)
              : "\u00A0"
          }
        ></Grid>
      </Card>

      {/* Edit */}
      {state.showEditUserModal && (
        <EditPersonModal
          userId={state.individual.id}
          onClose={(changed) =>
            updateState({
              showEditUserModal: false,
              gridReset: state.gridReset + (changed ? 1 : 0),
            })
          }
        />
      )}

      {/* Add to Group */}
      {state.showAddToGroupModal && (
        <Modal className="plain-modal">
          <p>Select a group you would like to add {getUsersText()} to.</p>
          <GuiSelect
            label="Group"
            name="addToGroupId"
            options={groupOptions}
            value={state.addToGroupId}
            onChange={(name, option) => updateState(name, option.value)}
          />
          <div className="buttons">
            <GuiButton
              className="btn-outline"
              onClick={(e) =>
                updateState({
                  showAddToGroupModal: false,
                  addToGroupId: null,
                  individual: null,
                })
              }
            >
              Cancel
            </GuiButton>
            <GuiButton
              onClick={addUsersToGroup}
              disabled={!state.addToGroupId}
              loading={state.saving}
            >
              Add {state.individual ? "" : Object.keys(checkboxState).length}{" "}
              User
              {state.individual || Object.keys(checkboxState).length === 1
                ? ""
                : "s"}
            </GuiButton>
          </div>
        </Modal>
      )}

      {/* Delete */}
      {state.showDeleteUsersModal && (
        <Modal className="plain-modal">
          <p>
            Are you sure you want to permanently remove {getUsersText()} from
            the system?
          </p>
          <div className="buttons">
            <GuiButton
              className="btn-outline"
              onClick={(e) =>
                updateState({ showDeleteUsersModal: false, individual: null })
              }
            >
              Cancel
            </GuiButton>
            <GuiButton
              className="warning"
              onClick={deleteUsers}
              loading={state.saving}
            >
              Delete {state.individual ? "" : Object.keys(checkboxState).length}{" "}
              User
              {state.individual || Object.keys(checkboxState).length === 1
                ? ""
                : "s"}
            </GuiButton>
          </div>
        </Modal>
      )}

      {/* High Priority */}
      {state.showMakeHighPriority && (
        <Modal className="plain-modal">
          <p>Would you like to make {getUsersText()} high priority?</p>
          <div className="buttons">
            <GuiButton
              className="btn-outline"
              onClick={(e) =>
                updateState({ showMakeHighPriority: false, individual: null })
              }
            >
              Cancel
            </GuiButton>
            <GuiButton
              onClick={(e) => setUserPriority(true)}
              loading={state.saving}
            >
              Make High Priority
            </GuiButton>
          </div>
        </Modal>
      )}

      {/* Low Priority */}
      {state.showRemoveHighPriority && (
        <Modal className="plain-modal">
          <p>
            Are you sure you want to remove high priority from {getUsersText()}?
          </p>
          <div className="buttons">
            <GuiButton
              className="btn-outline"
              onClick={(e) =>
                updateState({ showRemoveHighPriority: false, individual: null })
              }
            >
              Cancel
            </GuiButton>
            <GuiButton
              onClick={(e) => setUserPriority(false)}
              loading={state.saving}
            >
              Remove High Priority
            </GuiButton>
          </div>
        </Modal>
      )}
    </PageLayout>
  );
}

function UserTypeTag({ user, types }) {
  let typeId = user.userTypeId;
  if (typeId > 1) {
    let type = types.find((t) => t.value === typeId);

    if (type)
      return (
        <span className={"tag " + (typeId === 4 ? "admin" : "manager")}>
          {type.label}
        </span>
      );
  }
  return "";
}
