import { useEffect } from 'react';
import { useSelector } from 'react-redux';



export function useLazySelector(selector, loader) {
    let result = useSelector(selector);
    useEffect(() => {
        if (result === undefined)
            loader();
    }, []);
    return result;
}

export function useOnFirstLoad(action) {
    useEffect(action, []);
}