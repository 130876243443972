import '../styles/alert-details.scss';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as DOMPurify from 'dompurify';
import actions from '../actions/actions';
import { dispatch } from '../redux/redux-config';
import { AlertStatus, AlertType, Path } from '../utilities/constants';
import { formatDateLong } from '../utilities/utilities';
import { GuiButton } from '../components/global-ui/gui-button';
import AudioPlayer from '../components/audio-player';
import { ConfirmDeleteModal } from '../components/confirm-delete-modal';
import { useUpdateState } from '../utilities/utilities';
import { ConfirmCloseModal } from '../components/confirm-close-modal';
import { showToast } from '../components/toast';
import LoadingSpinner from '../components/loading-spinner';
import navigate from '../utilities/navigation';


export default function AlertDetails() {
    let alert = useSelector(store => store.alert.current);
    let isMobile = useSelector(store => store.page.isMobile);
    let canDelete = alert.status === AlertStatus.Scheduled || alert.status === AlertStatus.Draft;
    let [state, updateState] = useUpdateState({});
    let canEdit = alert.status === AlertStatus.Draft || alert.status === AlertStatus.Scheduled;

    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        let id = parseInt(params.get("id")) || 0;
        actions.alerts.loadAlertDetails(id);
    }, []);

    function back() {
        navigate("/");
    }

    function edit() {
        actions.alerts.showEditAlert(alert);
    }

    async function onDelete() {
        updateState({ deletingDraft: true });
        let result = await actions.alerts.deleteAlert(alert.id);
        updateState({ showConfirmDelete: false, deletingDraft: false });

        if (result.success) {
            dispatch("ALERT_SAVING", false);
            dispatch("RESET_MY_ALERTS");
            navigate(Path.Track);
            showToast("removed", "Your Alert has been removed.")
        }
    }

    return (
        <div className="alert-details">
            <div className="page-title">
                <span id="back" className="nav-button" onClick={back}><i className="far fa-chevron-left"></i>{isMobile ? "" : "Back"}</span>
                Alert Details
                {isMobile && canDelete && <i className="far fa-trash-alt" onClick={e => updateState({ showConfirmDelete: true })}></i>}
            </div>

            <div className="container-card">

                { /* Subject */}
                <div className="field-container">
                    <label>Subject</label>
                    <div>{alert.name}</div>
                </div>

                { /* Sent / Scheduled / Drafted */}
                <div className="field-container date">
                    {alert.status === AlertStatus.Sent && <>
                        <label>Sent on</label>
                        <div><i className="fas fa-paper-plane"></i>{formatDateLong(alert.scheduledDate)}</div>
                    </>}
                    {alert.status === AlertStatus.Scheduled && <>
                        <label>Scheduled For</label>
                        <div><i className="fas fa-regular fa-clock"></i>{formatDateLong(alert.scheduledDate)}</div>
                    </>}
                    {alert.status === AlertStatus.Draft && <>
                        <label>Drafted on</label>
                        <div><i className="fas fa-edit"></i>{formatDateLong(alert.scheduledDate)}</div>
                    </>}
                </div>

                { /* Recipients */}
                <div className="field-container">
                    <label>Recipients</label>
                    <div className="recipients-data">{alert.groups?.map(r => r.name).join(", ")}</div>
                </div>


                { /* Message */}
                {alert.alertType === AlertType.Text &&
                    <div>
                        <label>Preview Text</label>
                        <div>{alert.message}</div>
                    </div>
                }

                {alert.alertType === AlertType.Email &&
                    <div>
                        <label>Preview Email</label>
                        <div className="email-preview">
                            <div className="email-subject">
                                <span className="email-subject-header">Subject:</span>
                                {alert.name}
                            </div>
                            <div className="message-container" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(alert.message) }}></div>
                        </div>
                        <div className="attachments">
                            {alert.attachments && alert.attachments.map(a =>
                                <div key={a.url}>
                                    <i className="far fa-paperclip"></i>{a.name}
                                </div>
                            )}
                        </div>
                    </div>
                }

                {alert.alertType === AlertType.Voice &&
                    <div>
                        <label className="voice-label">Sound File</label>
                        {alert.voiceFileUrl &&
                            <div className="voice-preview">
                                <AudioPlayer src={alert.voiceFileUrl} />
                            </div>
                        }
                    </div>
                }
            </div>

            { /* Bottom Buttons */}
            {(isMobile && canDelete) ?
                <div className="btn-container mobile-three">
                    <GuiButton dataQa="other-btn" className="edit" onClick={edit}>{canEdit ? "Edit" : "Use as Draft"}</GuiButton>
                    <GuiButton dataQa="delete" className="btn-outline delete" onClick={e => updateState({ deleteConfirmClose: true })}>Delete</GuiButton>
                    <GuiButton dataQa="close" className="btn-outline close" onClick={back}>Close</GuiButton>
                </div>
                :
                <div className="btn-container">
                    {canDelete && <GuiButton dataQa="delete" className="btn-outline" onClick={e => updateState({ deleteConfirmClose: true })}>Delete</GuiButton>}
                    <GuiButton dataQa="close" className="btn-outline" onClick={back}>Close</GuiButton>
                    <GuiButton dataQa="other-btn" onClick={edit}>{canEdit ? "Edit" : "Use as Draft"}</GuiButton>
                </div>
            }

            {state.deleteConfirmClose && <ConfirmDeleteModal status="remove" onDelete={onDelete} onCancel={e => updateState({ deleteConfirmClose: false })} deleting={state.deletingDraft} />}
            {state.showConfirmDelete && <ConfirmCloseModal onDelete={onDelete} onCancel={e => updateState({ showConfirmDelete: false })} showConfirmDelete={true} />}
            <LoadingSpinner show={!alert.id} />
        </div>
    );
}