import { useEffect, useRef, useState } from "react";
import { showToast } from "../components/toast";

const useAutoLogout = (logoutFunction, timeout = 300000, warningTime = 30000, userLoaded) => {
  const timerRef = useRef(null); 
  const warningTimerRef = useRef(null);

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (warningTimerRef.current) {
      clearTimeout(warningTimerRef.current);
    }
    warningTimerRef.current = setTimeout(showWarning, timeout - warningTime);
    timerRef.current = setTimeout(handleLogout, timeout);
  };

  const showWarning = () => {
      showToast("error", "You will be logged out soon due to inactivity.");
  };

  const handleLogout = () => {
    if (warningTimerRef.current) {
      clearTimeout(warningTimerRef.current);
    }
    logoutFunction();
  };

  useEffect(() => {
    if (!userLoaded) return; 

    const events = ['mousemove', 'keydown', 'click', 'scroll'];

    const resetAndLogout = () => { 
      resetTimer();
    };

    events.forEach(event => window.addEventListener(event, resetAndLogout));

    resetTimer();

    return () => {
      events.forEach(event => window.removeEventListener(event, resetAndLogout));
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (warningTimerRef.current) {
        clearTimeout(warningTimerRef.current);
      }
    };
  }, [timeout, warningTime, logoutFunction, userLoaded]);

  return null; 
}; 

export default useAutoLogout;