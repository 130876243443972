import '../styles/template-picker.scss';
import React, { useEffect } from "react";
import { useSelector } from 'react-redux';
import * as DOMPurify from "dompurify";
import { GuiSelect } from "./global-ui/gui-select";
import { useUpdateState } from "../utilities/utilities";
import actions from '../actions/actions';
import { AlertType } from '../utilities/constants';
import { Modal } from './modal';
import { GuiButton } from './global-ui/gui-button';



export function TemplatePickerModal({ alertType, onInsert, onCancel }) {
    let templates = useSelector(store => store.alert.templates);
    let [state, updateState] = useUpdateState({});

    useEffect(() => {
        if (!templates || templates.length === 0)
            actions.alerts.loadTemplates(alertType);
    }, []);


    function update(name, item) {
        updateState({ template: item, preview: item.message });
    }

    return (
        <Modal className="template-picker-modal">
            <div className="template-picker">
                <h2>Message Templates</h2>
                <GuiSelect label="Template" name="template" value={state.template?.value} options={templates} onChange={update} inModal={true} />

                <div className="preview" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(state.preview) }}></div>

                <div className="button-container">
                    <GuiButton className="btn-outline" onClick={onCancel}>Cancel</GuiButton>
                    <GuiButton className="insert" onClick={() => onInsert(state.template)}>Insert</GuiButton>
                </div>
            </div>
        </Modal>
    );
}