import "../styles/schedule-alert-modal.scss";
import moment from "moment";
import "moment-timezone";
import React, { useState } from "react";
import { formatDateLongScheduled, parseDate } from "../utilities/utilities";
import { Modal } from "./modal";
import { GuiTime, parseDateTime } from "./global-ui/gui-time";
import { GuiButton } from "./global-ui/gui-button";
import GuiCalendar from "./global-ui/gui-calendar";

export default function ScheduleAlertModal({ value, onSave, onCancel }) {
  let [date, setDate] = useState(parseDateTime(value) || new Date());
  let [error, setError] = useState();
  let [isReady, setIsReady] = useState(false);

  let timezone = moment.tz.guess() || "America/New_York";
  let timeZoneLabel = moment().tz(timezone).format("z");

  function updateDate(name, value) {
    if (name === "date") {
      let d = parseDate(value);
      if (d) {
        d.setHours(date.getHours(), date.getMinutes(), 0);
        setDate(d);
      }
    }

    if (name === "time") {
      let d = new Date(date);
      if (value) d.setHours(value.getHours(), value.getMinutes(), 0);
      else d.setHours(0, 0, 0);
      setDate(d);
    }

    setError(null);
  }

  function onContinue() {
    if (date > new Date()) setIsReady(true);
    else setError("Please pick a future date and time.");
  }

  return (
    <>
      {!isReady && (
        <Modal className="schedule-alert-modal">
          <h4>Date</h4>
          <GuiCalendar name="date" value={date} onChange={updateDate} />

          <h4 className="time-label">Time ({timeZoneLabel})</h4>
          <GuiTime name="time" value={date} onChange={updateDate} />

          <span className="error-msg">{error || "\u00A0"}</span>

          <div className="sc-alert-btn-container">
            <GuiButton
              dataQa="cancel"
              className="btn-outline"
              onClick={onCancel}
            >
              Cancel
            </GuiButton>
            <GuiButton dataQa="continue" onClick={onContinue}>
              Continue
            </GuiButton>
          </div>
        </Modal>
      )}

      {isReady && (
        <Modal className="schedule-alert-modal-ready">
          <h4>Your alert is ready!</h4>
          <p className="modal-date">
            Send: {formatDateLongScheduled(date)} {timeZoneLabel}
          </p>

          <div className="btn-container">
            <GuiButton
              dataQa="cancel"
              className="btn-outline"
              onClick={onCancel}
            >
              Cancel
            </GuiButton>
            <GuiButton dataQa="scheduled" onClick={(e) => onSave(date)}>
              Schedule Alert
            </GuiButton>
          </div>
        </Modal>
      )}
    </>
  );
}
