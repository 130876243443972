import "../../styles/global-ui/gui-date.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import Calendar from 'react-input-calendar';
import ValidationContext from './validation';
import Error from "./error";



export function GuiDate({ name, value, label, onChange, disabled, className, error, minDate, maxDate }) {
    const validation = useContext(ValidationContext);
    const [inFocus, setInFocus] = useState(false);
    const div = useRef();
    const dateVal = parseDate(value);
    error = error || (validation && validation.show && validation.getError(name));

    useEffect(() => {
        // Apply advanced theme styling to datepicker plugin
        if (!div || !div.current)
            return;

        const input = div.current.querySelector("input.input-calendar-field");
        if (input) // null in completed-form mode
            input.readOnly = disabled; // Reset readonly because the plugin wants to go readOnly on mobile
    }, []);


    return (
        <div className={"gui-date " + (className || "") + (dateVal ? " has-value" : "") + (inFocus ? " in-focus" : "") + (error ? " has-error" : "")} ref={div}>
            <Calendar
                inputFieldClass={"input-calendar-field " + (disabled ? " disabled" : "")}
                format={dateFormat}
                date={dateVal}
                onFocus={e => setInFocus(true)}
                onBlur={e => setInFocus(false)}
                minDate={minDate}
                maxDate={maxDate}
                onChange={val => onChange ? onChange(name, val) : null}
                disabled={disabled || false}
                placeholder={datePlaceholder()}
                customIcon={"far fa-calendar" + (disabled ? " disabled" : "")}
                displayYrWithMonth={true}
                computableFormat="YYYY-MM-DD"
                closeOnSelect={true}
                hideTodayButton={true}
            />
            {label && <label>{label}</label>}
            <Error error={error} />
        </div>
    );
}



const dateFormat = new Date(2000, 10, 22).toLocaleDateString().replace("2000", "YYYY").replace("11", "MM").replace("22", "DD").replace(/\u200E/g, ''); // month 10 is novmember because javascript, \u200E is a left-to-right character that IE11 inserts
const browserMonthFirst = dateFormat.indexOf("MM") === 0;
export function parseDate(val, legacy = false) {
    if (!val)
        return null;

    if (typeof (val) === "string") {
        val = val.trim();
        if (val.indexOf('T') >= 0)
            val = val.substring(0, val.indexOf('T'));

        const parts = val.split(/[./-]/);
        if (parts.length !== 3)
            return null;

        let y, m, d;
        if (parts[0].length >= 3)
            [y, m, d] = parts.map(p => parseInt(p));
        else if (browserMonthFirst || (legacy && val.indexOf("/") > 0))
            [m, d, y] = parts.map(p => parseInt(p));
        else
            [d, m, y] = parts.map(p => parseInt(p));

        if (m === 0 || m > 12)
            return null;
        if (d === 0 || d > 31 || (d > 28 && d > daysInMonth(m, y)))
            return null;
        return new Date(y, m - 1, d);
    }
    else if (!(val instanceof Date))
        val = new Date(val);

    if (isNaN(val.getTime()))
        return null;
    return new Date(val.getFullYear(), val.getMonth(), val.getDate());
}

export function addDays(val, days) {
    var dt = parseDate(val);
    if (!dt)
        return null;
    dt.setDate(dt.getDate() + days);
    return dt;
}

export function getToday() {
    var now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}

export function getStartOfMonth() {
    var now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
}

export function getEndOfMonth() {
    var now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0);
}

export function equalDates(date1, date2) {
    const d1 = parseDate(date1);
    const d2 = parseDate(date2);
    if (!d1)
        return !d2;
    if (!d2)
        return false;
    return d1.valueOf() === d2.valueOf();
}

export let _datePlaceholder = null;
export function datePlaceholder() {
    return _datePlaceholder;
}

export function daysInMonth(month, year) {
    // Pass in the calendar month (2 for Februrary)
    return new Date(year, month, 0).getDate();
}